import React from 'react';
import styled from 'styled-components';
import Loader from '../tauria-react-components/molecules/Loader';
import { Result } from '../utilities/validate';

interface LoadingBGProps {
  opacity?: number;
}

const LoadingBG = styled.div<LoadingBGProps>`
  background-color: rgba(2, 8, 25, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 7;
  ${(p: LoadingBGProps): string => (p.opacity ? `opacity: ${p.opacity};` : '')}

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const Loading: React.FC<{ promise?: Promise<Result | undefined>; message: string }> = ({
  promise,
  message,
}) => {
  const [stateMessage, setMessage] = React.useState<Result | string | undefined>(message);

  React.useEffect(() => {
    let mounted = true;

    if (promise) {
      promise
        .then((newMessage?: Result): void => {
          if (mounted) {
            setMessage(newMessage);
          }
        })
        .catch((errorMessage?: Result): void => {
          if (mounted) {
            setMessage(errorMessage);
          }
        });
    }
    return (): void => {
      mounted = false;
    };
  }, [promise]);

  return promise ? (
    <LoadingBG>
      <Loader />
      <p>{stateMessage}</p>
    </LoadingBG>
  ) : null;
};

/**
 *
 * @param loading : the loader above uses a boolean instead of a promise, this will make it easier for the component to be embedded anywhere within the app
 */
export const LoadingSync: React.FC<{ loading?: boolean; message: string }> = ({
  loading,
  message,
}) => {
  return loading ? (
    <LoadingBG>
      <Loader />
      <p>{message}</p>
    </LoadingBG>
  ) : (
    <div />
  );
};

export default Loading;
