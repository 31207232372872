import React, { useState } from 'react';
import Form, { FormInputOptions as InputOptions } from '../tauria-react-components/molecules/Form';
import PasswordStrength, {
  PasswordStrengthInputOptions,
} from '../tauria-react-components/molecules/FormRenderers/PasswordStrength';
import Base from '../pages/Base';
import { Result } from '../utilities/validate';
import ReadOnly from '../tauria-react-components/molecules/FormRenderers/ReadOnly';
import { Headings } from '../styles';
import { getPasswordFormErrors, getPasswordFormCriteria } from '../utilities/password-criteria';
import Loading from '../views/Loading';

const Password: React.FC<{
  storePassword: (data: Record<string, string>) => Promise<Result | undefined>;
  givenEmail: string | undefined;
  newUser?: boolean;
}> = ({ storePassword, newUser, givenEmail }) => {
  const [errors, setErrors] = useState({});
  const [criteria, setCriteria] = useState(getPasswordFormCriteria({}));
  const [loadingPromise, setLoadingPromise] = useState<Promise<Result | undefined> | undefined>(
    undefined,
  );

  const validatePasswords = React.useCallback(
    async (values: Record<string, string>): Promise<void> => {
      try {
        const newErrors = getPasswordFormErrors(values);

        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }

        const promise = storePassword(values);

        if (newUser) {
          setLoadingPromise(promise);
        }

        const potentialErrors = await promise;

        if (newUser) {
          setTimeout(() => {
            setLoadingPromise(undefined);
          }, 2000);
        }

        if (potentialErrors) {
          setErrors({ ALL: potentialErrors });
        }
      } catch (error) {
        setErrors({ ALL: error?.message || error });
      }
    },
    [storePassword, newUser],
  );

  const handleChange = React.useCallback((values: Record<string, string>): void => {
    setCriteria(getPasswordFormCriteria(values));

    // Reset Errors on change
    setErrors({});
  }, []);

  return (
    <>
      <Base>
        <Headings>
          <h1>Reset Your Password</h1>
          <h2>Set a new password for your account</h2>
        </Headings>
        <Form
          inputs={[
            {
              name: 'EMAIL',
              area: 'email',
              type: 'text',
              renderer: givenEmail ? ReadOnly : undefined,
              value: givenEmail,
              autoFocus: true,
            } as InputOptions & { value: string },
            {
              name: 'PASSWORD',
              area: 'password',
              type: 'password',
              renderer: PasswordStrength,
              criteria,
            } as PasswordStrengthInputOptions,
            { name: 'CONFIRM PASSWORD', area: 'confirm', type: 'password' },
          ]}
          errors={errors}
          submit={{ name: 'NEXT', area: 'submit', type: 'button' }}
          rows={[1, 1]}
          colms={[1]}
          areas={[['email'], ['password'], ['confirm'], ['submit']]}
          onSubmit={validatePasswords}
          onChange={handleChange}
        />
      </Base>
      <Loading promise={loadingPromise} message="Creating your user. Thank you" />
    </>
  );
};

export default Password;
