/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useCaptcha } from '@developers/use-captcha';
import Encryption from '../utilities/encryption';
import Base from './basePage';

import Banner from '../views/Banner';
import Details from '../RegisterPages/Details';
import Password from '../RegisterPages/Password';
import ExistingUser from '../RegisterPages/ExistingUser';
import Description from '../newUserPages/Description';
import { Result } from '../utilities/validate';
import registerApi from '../models/registerApi';
import connectApi from '../models/connectApi';

const pages = ['details', 'password'];

const addPage = (pageName: string, position: number): string[] =>
  pages.splice(position - 1, 0, pageName);

export interface StateMachine {
  step: number;
  email?: string;
  passwordHash: string;
  loginHash: string;
  firstName: string;
  lastName: string;
  token: string;
  prefix: string;
  existingUser: boolean;
  extraPage: boolean;
}

const NewUsersContent: React.FC<{ token: string; prefix: string }> = ({ token, prefix }) => {
  const history = useHistory();
  const recaptcha = useCaptcha();
  const [state, setState] = useState<StateMachine>({
    step: 1,
    token,
    prefix,
    email: undefined,
    existingUser: false,
    passwordHash: '',
    loginHash: '',
    firstName: '',
    lastName: '',
    extraPage: false,
  });

  useEffect(() => {
    const getEmail = async (): Promise<void> => {
      try {
        const { email, extraPages } = await registerApi.GetNewUserEmail(prefix, token);
        const newState = { ...state };
        if (email !== '') {
          newState.email = email;
        }
        if (extraPages !== undefined) {
          addPage(extraPages, 2);
          newState.extraPage = true;
        }
        setState(newState);
      } catch (e) {
        if (e === 406) {
          setState({ ...state, existingUser: true });
        }
      }
    };
    if (state.email === undefined) {
      getEmail();
    }
  }, [history, prefix, token, state]);

  useEffect(() => {
    document.title = 'User Details - Tauria';
  }, []);

  // This effect is used to redirect the user back to the sign-in screen if they exist already
  useEffect(() => {
    let timeout: number;

    if (state.existingUser) {
      timeout = setTimeout(() => {
        history.push('/');
      }, 7500);
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [history, state.existingUser]);

  const handleStoreDetails = (values: any): void => {
    const newState = { ...state };
    newState.email = values['WORK EMAIL'];
    newState.firstName = values['FIRST NAME'];
    newState.lastName = values['LAST NAME'];
    newState.step += 1;
    setState(newState);
  };

  const handleStorePassword = async (values: any): Promise<Result | undefined> => {
    const newState = { ...state };
    newState.passwordHash = Encryption.encryptPassword(values.PASSWORD);
    newState.loginHash = Encryption.getLoginHash(values.PASSWORD);
    // skip captcha
    const captchaToken = await recaptcha.execute('register_new_user');
    const result = await registerApi.addNewUser(
      newState.prefix,
      newState.token,
      newState,
      captchaToken,
    );

    if (result === Result.Success) {
      setState({ ...state, step: state.step += 1 });
      if (state.email) {
        connectApi.login({
          prefix: state.prefix,
          passwordHash: newState.passwordHash,
          email: state.email,
          loginHash: newState.loginHash,
        });
        return Result.Success;
      }
    }
    return Result.GeneralError;
  };

  const handleStoreDescription = (values: any): void => {
    const newState: any = { ...state };
    newState.position = values['JOB TITLE'];
    newState.description = values['COMPANY BIO'];
    newState.phoneNumber = values['PHONE NUMBER'];
    newState.step += 1;
    setState(newState);
  };

  const getPage = (): JSX.Element | null => {
    if (state.existingUser) {
      return <ExistingUser />;
    }

    if (pages[state.step - 1] === 'details') {
      return <Details storeDetails={handleStoreDetails} givenEmail={state.email} />;
    }

    if (pages[state.step - 1] === 'password') {
      return <Password storePassword={handleStorePassword} newUser />;
    }

    if (pages[state.step - 1] === 'description') {
      return <Description storeDescription={handleStoreDescription} />;
    }

    return null;
  };
  return (
    <>
      <div style={{ gridArea: 'banner' }}>
        <Banner
          userStep={state.step}
          userExtraStep={state.extraPage}
          showBack={state.step > 1}
          handleBack={(): void => {
            setState({ ...state, step: state.step - 1 });
          }}
        />
      </div>
      <div style={{ gridArea: 'form' }}>{getPage()}</div>
    </>
  );
};

const NewUsers = (): JSX.Element => {
  const { token, prefix } = useParams() as { token: string; prefix: string };
  return <Base Content={NewUsersContent} token={token} prefix={prefix} />;
};

export default NewUsers;
