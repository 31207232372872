/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { Result } from '../utilities/validate';
import ConfigManager from '../config';

const { config } = new ConfigManager(window.location.origin);
const baseUrl = config.backend.auth;
const baseUrlv2 = `${config.backend.auth}/v2`;

const requestOptions: RequestInit = {
  method: 'POST',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
};

interface UserSignupRes {
  email: string;
  name: string;
  last_name: string;
  login: string;
  success: boolean;
}

interface InviterCompany {
  organization: string;
  passphrase_crypt: boolean;
}

export default {
  async getUserOrgs(
    email: string,
    password: string,
    captcha: string,
    passwordLegacy?: string,
  ): Promise<Response> {
    const data: URLSearchParams = new URLSearchParams();

    // If mobile app we may need to force a version update so we send the app details to the server
    const isMobileApp = window.navigator.userAgent.includes('ZebuApp');
    if (isMobileApp) {
      const appUA = window.navigator.userAgent.split('ZebuApp')[1];
      const appPlatform = appUA.split('/')[1];
      const appVersion = appUA.split('/')[2];
      data.append('app_version', appVersion);
      data.append('app_platform', appPlatform);
    }

    data.append('email', email);
    data.append('password', password);
    data.append('captchaToken', captcha);

    if (passwordLegacy) data.append('oldpassword', passwordLegacy);

    const options = { ...requestOptions, body: data };

    try {
      const resp: Response = await fetch(`${baseUrl}/orgsignin`, options);
      return resp;
    } catch (err) {
      return err;
    }
  },
  async verifyOrgName(name: string): Promise<Result> {
    try {
      const resp: Response = await fetch(`${baseUrl}/verify/org_exists/${name}`);
      if (resp.status === 200) {
        return Result.Success;
      }
      if (resp.status === 409) {
        return Result.OrgExists;
      }
      return Result.GeneralError;
    } catch (e) {
      return Result.GeneralError;
    }
  },

  async validate2FA(
    email: string,
    prefix: string,
    code: string,
    captcha: string,
  ): Promise<boolean | undefined> {
    try {
      const params = new URLSearchParams();
      params.append('prefix', prefix);
      params.append('code', code);
      params.append('email', email);
      params.append('captchaToken', captcha);

      const response = await fetch(`${baseUrlv2}/twofa`, {
        ...requestOptions,
        body: params,
      });
      if (response.ok) {
        const success = await response.json();
        return success.success;
      }
      return false;
    } catch (e) {
      return undefined;
    }
  },

  async forgotPasword(email: string, captcha: string): Promise<boolean | undefined> {
    try {
      const params = new URLSearchParams();
      params.append('email', email);
      params.append('captchaToken', captcha);

      const response = await fetch(`${baseUrl}/v2/forgot_password`, {
        ...requestOptions,
        body: params,
      });
      if (response.ok) {
        return true;
      }
      return false;
    } catch (e) {
      return undefined;
    }
  },

  async passwordResetInfo(token: string, prefix: string): Promise<UserSignupRes | undefined> {
    const url = `${baseUrl}/v1/user/signup/${prefix}/${token}`;
    try {
      const response = await fetch(url);
      return (await response.json()) as UserSignupRes;
    } catch (e) {
      return undefined;
    }
  },

  async checkB2BToken(token: string): Promise<InviterCompany | undefined> {
    const url = `${baseUrl}/v1/b2b/invite/${token}`;
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const respData = await response.json();
        return respData.inviter_company_info;
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  },

  async validateB2BPassphrase(
    token: string,
    passphraseHash: string,
    passphraseHashLegacy?: string,
  ): Promise<Response> {
    const data: URLSearchParams = new URLSearchParams();

    data.append('token', token);
    data.append('passphraseHash', passphraseHash);
    if (passphraseHashLegacy) data.append('oldpassphraseHash', passphraseHashLegacy);

    const options = { ...requestOptions, body: data };

    try {
      const resp: Response = await fetch(`${baseUrl}/b2b/passphrase`, options);
      return resp;
    } catch (err) {
      return err;
    }
  },
};
