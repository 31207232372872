import React from 'react';
import Base from '../pages/Base';
import { Headings } from '../styles';

const ContactSupport = (): JSX.Element => {
  return (
    <Base showFooter>
      <Headings>
        <h1>Error</h1>
      </Headings>
      <p>An error has occured. Please contact support.</p>
    </Base>
  );
};

export default ContactSupport;
