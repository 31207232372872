/* eslint-disable no-param-reassign */
import React, { useState } from 'react';

import Form from '../tauria-react-components/molecules/Form';
import Base from '../pages/Base';
import { Headings } from '../styles';

const isNotEmpty = (input: string): undefined | 'Mandatory Field' =>
  input.length > 0 ? undefined : 'Mandatory Field';

const hasError = (e: any): boolean =>
  Object.values(e).reduce<boolean>((acc, cur) => (acc === true ? acc : !!cur), false);

const Description = (props: { storeDescription: (inputs: any) => void }): JSX.Element => {
  const [errors, setErrors] = useState({});

  const validateInputs = (values: {
    'JOB TITLE': string;
    'COMPANY BIO': string;
    'PHONE NUMBER': string;
  }): void => {
    const e = {
      'JOB TITLE': isNotEmpty(values['JOB TITLE']),
      'COMPANY BIO': isNotEmpty(values['COMPANY BIO']),
      'PHONE NUMBER': isNotEmpty(values['PHONE NUMBER']),
    };
    setErrors(e);
    if (!hasError(e)) props.storeDescription(values);
  };

  return (
    <>
      <Base showFooter={false}>
        <Headings>
          <h1>User Information</h1>
          <h2>Tell your team a little about yourself</h2>
        </Headings>
        <Form
          inputs={[
            {
              name: 'JOB TITLE',
              area: 'position',
              autoFocus: true,
              type: 'text',
            },
            { name: 'PHONE NUMBER', area: 'phone', type: 'text' },
            { name: 'COMPANY BIO', area: 'desc', type: 'text' },
          ]}
          errors={errors}
          submit={{ name: 'NEXT', area: 'submit', type: 'button' }}
          rows={[1, 1]}
          colms={[1]}
          areas={[['position'], ['phone'], ['desc'], ['submit']]}
          onSubmit={validateInputs}
        />
      </Base>
    </>
  );
};

export default Description;
