import React from 'react';
import Base from '../pages/Base';
import { Headings } from '../styles';

const ExistingUser = (): JSX.Element => {
  return (
    <Base showFooter={false}>
      <Headings>
        <h1>Existing User</h1>
        <h2>It looks like you have already signed up with this link</h2>
      </Headings>
      <p>You will be redirected to the Sign-in screen in a moment...</p>
    </Base>
  );
};

export default ExistingUser;
