/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';

import Layout from '../tauria-react-components/molecules/Layout';
import ResponsiveLayout from '../tauria-react-components/molecules/ResponsiveLayout';

const ResponsivePage = styled.div`
  height: 100vh;
  width: 100vw;
  @media only screen and (max-width: 900px) {
    height: auto;
    min-height: 100vh;
  }
`;

const Base: React.FC<{ Content: any; token?: string; prefix?: string; b2b?: boolean }> = ({
  Content,
  token,
  prefix,
  b2b,
}) => {
  const DesktopLayout = (
    <Layout rows={[1]} colms={[1, 1]} areas={[['banner', 'form']]}>
      <Content token={token} prefix={prefix} b2b={b2b} />
    </Layout>
  );

  const MobileLayout = (
    <Layout rows={[3 / 4, 1]} colms={[1]} areas={[['banner'], ['form']]}>
      <Content token={token} prefix={prefix} b2b={b2b} />
    </Layout>
  );

  return (
    <ResponsivePage>
      <ResponsiveLayout layouts={[MobileLayout, DesktopLayout]} breakpoints={[900, 1920]} />
    </ResponsivePage>
  );
};

export default Base;
