import React, { useState } from 'react';
import styled from 'styled-components';
import Base from '../pages/Base';
import useDownload from '../tauria-react-components/hooks/useDownload';
import { Link as LinkButton, Button } from '../tauria-react-components/particles/Button';
import { ResendingLoader } from './Resend';
import { Headings } from '../styles';
import { sendEvent, sendPageView } from '../utilities/hubspot';

const LinkButtonStyle = {
  width: 'auto',
  color: '#1577FE',
  marginLeft: '6px',
  fontSize: '16px',
  fontWeight: 600,
};

const S = {
  email: styled.div`
    color: #191a21;
    font-size: 16px;
    background-color: rgba(211, 216, 224, 0.2);
    padding: 10px 113px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
  `,
  sbs: styled.div`
    display: flex;
    margin-top: 15px;
    & > p {
      margin-bottom: 0px;
      margin-top: 0px;
      color: #4d4d4d;
    }
    & > button {
      padding: 0px;
    }
  `,
  emailContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
};

const Details: React.FC<{
  email: string;
  onResendEmail: (showLoadingScreen: () => void, hideLoadingScreen: () => void) => void;
  onWrongEmail: () => void;
}> = ({ email, onResendEmail, onWrongEmail }) => {
  const download = useDownload((url: string): void => {
    window.location.href = url;
    sendEvent('Download App Clicked');
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isOnDesktop = !!(window as any).isDesktopApp;
  const [showLoading, __setShowLoading] = useState(false);
  const [heading, setHeading] = useState('Success!');

  React.useEffect(() => {
    document.title = 'Registration Success';
    sendPageView(`${window.location.pathname}#registration-success`);
  }, []);

  // Added showFooter to resolve issue #https://gitlab3.tauria.com/developers/signin/zebu-signin-2020/-/issues/25
  return (
    <Base showFooter>
      <Headings>
        <h2 style={{ marginBottom: '0.25rem', fontWeight: 600 }}>{heading}</h2>
        <h1 style={{ marginBottom: 0 }}>Verify Your Email</h1>
      </Headings>
      <div>
        <p>Please confirm the email we sent to:</p>
        <S.emailContainer>
          <S.email>{email}</S.email>
        </S.emailContainer>
        <S.sbs style={{ marginTop: '25px' }}>
          <p>• Have trouble receiving the email.</p>
          <LinkButton
            value="Re-send email"
            onClick={async (): Promise<void> => {
              sendEvent('Re-send Email clicked');
              await onResendEmail(
                () => __setShowLoading(true),
                () => __setShowLoading(false),
              );
              setHeading('Resent!');
            }}
            style={LinkButtonStyle}
          />
        </S.sbs>
        <S.sbs>
          <p>• Wrong email?</p>
          <LinkButton
            value="Change your email"
            onClick={(): void => {
              sendEvent('Change Email clicked');
              return onWrongEmail();
            }}
            style={LinkButtonStyle}
          />
        </S.sbs>
        {!isOnDesktop && (
          <Button
            style={{ padding: '20px', marginTop: '80px' }}
            onClick={download}
            value="DOWNLOAD APP"
          />
        )}
      </div>
      <ResendingLoader showLoading={showLoading} />
    </Base>
  );
};

export default Details;
