import React from 'react';
import { LabeledInput, ErrorField } from '../../particles/Input';
import { FormInputOptions as InputOptions } from '../../molecules/Form';

export default (
  input: InputOptions & { value: string },
  key: number,
  errors: any,
  onChange: any,
) => (
  <div key={key} style={{ gridArea: input.area }}>
    <LabeledInput
      key={input.name}
      id={input.area}
      style={{ gridArea: input.area }}
      label={input.name}
      type={input.type}
      headingStyle={input.headingStyle}
      error={!!errors[input.name] || !!errors.ALL}
      width="100%"
      onChange={onChange}
      value={input.value}
      readOnly
    />
    {errors[input.name] ? <ErrorField>{errors[input.name]}</ErrorField> : ''}
  </div>
);
