export const sendPageView = (url: string): void => {
  const _hsq = (window._hsq = window._hsq || []);

  _hsq.push(['setPath', url]);
  _hsq.push(['trackPageView']);
};

type Identity = {
  email: string;
  [key: string]: unknown;
};

export const setIdentity = (identity: Identity): void => {
  const _hsq = (window._hsq = window._hsq || []);

  _hsq.push(['identify', identity]);
};

export const sendEvent = (eventId: string | number, eventValue?: number): void => {
  const _hsq = (window._hsq = window._hsq || []);

  _hsq.push([
    'trackEvent',
    {
      id: eventId,
      value: eventValue,
    },
  ]);
};
