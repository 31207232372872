import React from 'react';
import { LabeledInput, ErrorField } from '../../particles/Input';

export default (input: any, key: number, errors: any, onChange: any) => (
  <div key={key} style={{ gridArea: input.area }}>
    <LabeledInput
      key={input.name}
      id={input.area}
      style={{ gridArea: input.area }}
      label={input.name}
      type={input.type}
      autoFocus={!!input.autoFocus}
      headingStyle={input.headingStyle}
      error={!!errors[input.name] || !!errors.ALL}
      width="100%"
      onChange={onChange}
      limit={input.limit}
    />
    {errors[input.name] ? <ErrorField>{errors[input.name]}</ErrorField> : ''}
  </div>
);
