import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import Button from '../tauria-react-components/particles/Button';

const Container = styled.div`
  width: calc(100% - 160px);
  height: calc(100% - 145px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 80px;

  @media only screen and (max-width: 400px) {
    padding: 20px 10px;
    width: calc(100% - 20px);
  }

  @media only screen and (max-width: 600px) {
    height: calc(100% - 105px);
  }

  @media only screen and (max-width: 1200px) {
    padding: 20px 40px;
    width: calc(100% - 80px);
  }
`;

const Content = styled.div`
  margin: auto;
  width: 100%;

  > p {
    margin-bottom: 1.5rem;
    line-height: calc(20 / 14);
  }
`;

const Footer = styled.div`
  margin-top: auto;
  background-color: #f7f8f9;
  width: calc(100% - 40px);
  height: 65px;
  color: #3c4b71;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 1.125rem;

  button {
    margin-left: 20px;
    width: auto;

    @media only screen and (max-width: 900px) {
      margin-left: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 10px 20px;
    height: 45px;
    font-size: 0.875rem;
  }

  > * {
    width: 30%;
    @media only screen and (max-width: 600px) {
      width: auto;
    }
  }
`;

const Base: React.FC<{
  buttonHref?: string;
  buttonLabel?: string;
  label?: string;
  showFooter?: boolean;
}> = ({
  children,
  buttonHref = '/',
  label = 'Already have a workspace?',
  buttonLabel = 'SIGN IN',
  showFooter = true,
}) => {
  const history = useHistory();

  return (
    <>
      <Container>
        <Content>{children}</Content>
      </Container>
      {showFooter && (
        <Footer>
          {label}
          <Button
            onClick={(): void => {
              history.push(buttonHref);
            }}
            value={buttonLabel}
            color="#0a1e51"
          />
        </Footer>
      )}
    </>
  );
};

export default Base;
