import React, { useState } from 'react';
import Form, { FormInputOptions as InputOptions } from '../tauria-react-components/molecules/Form';
import Base from '../pages/Base';
import validate, { Result } from '../utilities/validate';
import ReadOnly from '../tauria-react-components/molecules/FormRenderers/ReadOnly';
import { Headings } from '../styles';
import { sendPageView } from '../utilities/hubspot';

const Details: React.FC<{
  storeDetails: (data: Record<string, string>) => void;
  givenEmail?: string;
}> = ({ storeDetails, givenEmail }) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  React.useEffect(() => {
    document.title = 'Personal Details';
    sendPageView(`${window.location.pathname}#personal-details`);
  }, []);

  const handleSubmit = React.useCallback(
    (values: Record<string, string>): void => {
      const firstName = values['FIRST NAME'].trim();
      const lastName = values['LAST NAME'].trim();
      const email = values['WORK EMAIL'].trim();

      const tempErrors: Record<string, string> = {};

      if (validate.checkEmpty([firstName]) === Result.EmptyField) {
        tempErrors['FIRST NAME'] = Result.RequiredField;
      }

      if (validate.checkEmpty([lastName]) === Result.EmptyField) {
        tempErrors['LAST NAME'] = Result.RequiredField;
      }

      if (validate.checkEmpty([email]) === Result.EmptyField) {
        tempErrors['WORK EMAIL'] = Result.RequiredField;
      }

      if (tempErrors['FIRST NAME'] && tempErrors['LAST NAME'] && tempErrors['WORK EMAIL']) {
        delete tempErrors['FIRST NAME'];
        delete tempErrors['LAST NAME'];
        delete tempErrors['WORK EMAIL'];
        tempErrors.ALL = Result.EmptyField;
      }

      if (
        !tempErrors['WORK EMAIL'] &&
        !tempErrors.ALL &&
        validate.checkEmail(email) === Result.InvalidEmail
      ) {
        tempErrors['WORK EMAIL'] = Result.InvalidEmail;
      }

      if (Object.keys(tempErrors).length === 0) {
        storeDetails(values);
      } else {
        setErrors(tempErrors);
      }
    },
    [storeDetails],
  );

  const handleChange = React.useCallback(() => {
    setErrors({});
  }, []);

  return (
    <Base showFooter={false}>
      <Headings>
        <h1>Personal Details</h1>
        <h2>Fill in some information about yourself</h2>
      </Headings>
      <Form
        inputs={[
          { name: 'FIRST NAME', area: 'first', type: 'text', autoFocus: true },
          { name: 'LAST NAME', area: 'last', type: 'text' },
          {
            name: 'WORK EMAIL',
            area: 'email',
            type: 'text',
            renderer: givenEmail ? ReadOnly : undefined,
            value: givenEmail,
          } as InputOptions & { value: string },
        ]}
        errors={errors}
        submit={{ name: 'NEXT', area: 'submit', type: 'button' }}
        rows={[1, 1]}
        colms={[1, 1]}
        areas={[
          ['first', 'last'],
          ['email', 'email'],
          ['submit', 'submit'],
        ]}
        onSubmit={handleSubmit}
        onChange={handleChange}
      />
    </Base>
  );
};

export default Details;
