import React from 'react'
import useWindowSize from '../hooks/useWindowSize'

interface RLayoutProps {
  layouts: JSX.Element[]
  breakpoints: number[]
}

const ResponsiveLayout: React.FC<RLayoutProps> = ({layouts, breakpoints}) => {

  const size = useWindowSize();

  let i = 0;

  if(size.width > breakpoints[breakpoints.length - 1]) return layouts[layouts.length - 1]

  while (i < breakpoints.length - 1){
    if(size.width < breakpoints[i]){
      return layouts[i]
    }
    i += 1
  }

  return layouts[layouts.length - 1]
}

export default ResponsiveLayout
