import React from 'react';
import { useHistory } from 'react-router';
import Form from '../tauria-react-components/molecules/Form';
import { Link as LinkButton } from '../tauria-react-components/particles/Button';
import EmailPasswordRenderer from '../tauria-react-components/molecules/FormRenderers/EmailPassword';
import useWindowSize from '../tauria-react-components/hooks/useWindowSize';
import Validate, { Result } from '../utilities/validate';
import Base from '../pages/Base';
import { Headings, FormTopSpacer } from '../styles';
import { LoadingSync } from './Loading';

type SigninProps = {
  onLogin: (email: string, password: string) => Promise<{ error: string } | undefined>;
  isNextStage?: boolean;
};

const Signin = ({ onLogin, isNextStage }: SigninProps): JSX.Element => {
  const history = useHistory();
  const [errors, setErrors] = React.useState({});
  const windowSize = useWindowSize();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSignin = async (values: Record<string, string>): Promise<void> => {
    const maybeErrors: Record<string, string> = {};

    const email = values['EMAIL ADDRESS'];
    const password = values.PASSWORD;

    if (Validate.checkEmpty([email]) !== Result.Success) {
      maybeErrors['EMAIL ADDRESS'] = Result.RequiredField;
    }

    if (Validate.checkEmpty([password]) !== Result.Success) {
      if (maybeErrors['EMAIL ADDRESS']) {
        maybeErrors.ALL = Result.EmptyField;
        delete maybeErrors['EMAIL ADDRESS'];
        setErrors(maybeErrors);
        return;
      }

      maybeErrors.PASSWORD = Result.RequiredField;
    }

    if (!maybeErrors['EMAIL ADDRESS'] && Validate.checkEmail(email) !== Result.Success) {
      maybeErrors['EMAIL ADDRESS'] = Result.InvalidEmail;
    }

    if (Object.keys(maybeErrors).length === 0) {
      setLoading(true);
      try {
        const responseError = await onLogin(email, password);

        if (responseError) {
          setErrors({ ALL: responseError.error });
          setLoading(false);
        } else {
          setErrors({});
        }
      } catch (error) {
        setErrors({ ALL: Result.GeneralError });
        setLoading(false);
      }
    } else {
      setErrors(maybeErrors);
    }
  };

  const handleChange = React.useCallback((): void => {
    // Reset Errors on change
    setErrors({});
  }, []);

  // const handlePasswordEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  //   e.preventDefault();
  //   const values: Record<string, string> = {};
  //   const email: HTMLInputElement | null = document.querySelector(`#email`);
  //   const password: HTMLInputElement | null = document.querySelector(`#password`);
  //   if (email) values['EMAIL ADDRESS'] = email.value;
  //   if (password) values.PASSWORD = password.value;
  //   handleSignin(values);
  // };

  return (
    <>
      <Base buttonHref="/register" buttonLabel="CREATE ONE" label="Don't have an account?">
        {windowSize.width >= 900 && (
          <Headings>
            <h1>Sign In</h1>
            <h2>Welcome to Tauria</h2>
          </Headings>
        )}
        <FormTopSpacer>
          <Form
            submit={{ area: 'signin', name: 'SIGN IN', type: 'button' }}
            rows={[1, 1]}
            colms={[1]}
            areas={[['email'], ['password'], ['signin']]}
            inputs={[
              { name: 'EMAIL ADDRESS', area: 'email', type: 'email', autoFocus: true },
              { name: 'PASSWORD', area: 'password', type: 'password' },
            ]}
            errors={errors}
            onSubmit={handleSignin}
            onChange={handleChange}
            renderer={EmailPasswordRenderer}
            headingStyle={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#6E7D97',
            }}
          />
          <LinkButton
            value="Forgot your password?"
            onClick={(): void => {
              history.push(`/forgot_your_password`);
            }}
          />
        </FormTopSpacer>
      </Base>
      <LoadingSync loading={loading && !isNextStage} message="Signing you in, please wait." />
    </>
  );
};

export default Signin;
