class ColorManager {
  blue = '#1577FE';
  green = '#0ED266';
  lightGreen = 'rgba(14, 210, 102, 0.1)';
  grey = '#5B6074';
  lightGrey = '#F7F7F9';
  white = '#fff';
  red = '#F15C5A';
  get default() {
    return this.blue;
  }
}

export default ColorManager;
