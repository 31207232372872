/* eslint-disable */
declare global {
  namespace NodeJS {
    export interface ProcessEnv {
      readonly NODE_ENV: 'development' | 'production' | 'test';
      readonly PUBLIC_URL: string;

      readonly REACT_APP_DEV_BACKEND_SERVER: string;
      readonly REACT_APP_DEV_REGISTER_SERVER: string;
      readonly REACT_APP_DEV_ANALYTICS_SERVER: string;
      readonly REACT_APP_DEV_DOMAIN: string;
      readonly REACT_APP_DEV_RECAPTCHA_SITE_KEY: string;
      readonly REACT_APP_DEV_CAPTCHA: string;

      readonly REACT_APP_PRD_BACKEND_SERVER: string;
      readonly REACT_APP_PRD_REGISTER_SERVER: string;
      readonly REACT_APP_PRD_ANALYTICS_SERVER: string;
      readonly REACT_APP_PRD_DOMAIN: string;
      readonly REACT_APP_PRD_RECAPTCHA_SITE_KEY: string;
      readonly REACT_APP_PRD_CAPTCHA: string;

      readonly REACT_APP_FAT_BACKEND_SERVER: string;
      readonly REACT_APP_FAT_REGISTER_SERVER: string;
      readonly REACT_APP_FAT_ANALYTICS_SERVER: string;
      readonly REACT_APP_FAT_DOMAIN: string;
      readonly REACT_APP_FAT_RECAPTCHA_SITE_KEY: string;
      readonly REACT_APP_FAT_CAPTCHA: string;

      readonly REACT_APP_LOCAL_BACKEND_SERVER: string;
      readonly REACT_APP_LOCAL_REGISTER_SERVER: string;
      readonly REACT_APP_LOCAL_ANALYTICS_SERVER: string;
      readonly REACT_APP_LOCAL_DOMAIN: string;
      readonly REACT_APP_LOCAL_RECAPTCHA_SITE_KEY: string;
      readonly REACT_APP_LOCAL_CAPTCHA: string;
    }
  }
}

type TAURIA_ENV = 'DEV' | 'FAT' | 'LOCAL' | 'PRD';

interface Config {
  backend: {
    auth: string;
    register: string;
    analytics: string;
  };
  captcha: string;
  captcha_key: string;
  domain: string;
  env: TAURIA_ENV;
}

export class configManager {
  private _config: Config;

  constructor(origin: string) {
    this._config = this.createConfig(this.getDomainFromOrigin(origin));
  }

  private getDomainFromOrigin = (origin: string = ''): TAURIA_ENV =>
    origin.includes('dev')
      ? 'DEV'
      : origin.includes('fat')
      ? 'FAT'
      : origin.includes('localhost')
      ? 'LOCAL'
      : 'PRD';

  private createConfig = (env: TAURIA_ENV): Config =>
    ({
      backend: {
        register: process.env[`REACT_APP_${env}_REGISTER_SERVER`],
        auth: process.env[`REACT_APP_${env}_BACKEND_SERVER`],
        analytics: process.env[`REACT_APP_${env}_ANALYTICS_SERVER`],
      },
      captcha: process.env[`REACT_APP_${env}_CAPTCHA`],
      captcha_key: process.env[`REACT_APP_${env}_RECAPTCHA_SITE_KEY`],
      domain: process.env[`REACT_APP_${env}_DOMAIN`],
      env,
    } as Config);

  get config(): Config {
    return this._config;
  }
}

export default configManager;
