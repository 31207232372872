import React, { useEffect } from 'react';

const ClearStorage = (): JSX.Element => {
  useEffect(() => {
    localStorage.clear();
    setTimeout(() => {
      console.info('redirecting...');
      window.location.replace('https://signup.zebu.io');
    }, 1000);
  }, []);

  return <div />;
};

export default ClearStorage;
