/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import ConfigManager from '../config';
import { sendEvent, sendPageView, setIdentity } from '../utilities/hubspot';

const { config } = new ConfigManager(window.location.origin);
export interface SignupData {
  email: string;
  passwordHash: string;
  prefix: string;
  loginHash: string;
  inviterOrganization?: string | null;
  pin?: string;
}

export interface ResetData {
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  passwordHash: string;
  confirmPasswordHash: string;
  prefix: string;
  token: string;
}

const Connect = {
  login(data: SignupData): void {
    // HubSpot tracking
    setIdentity({
      email: data.email,
      inviterOrganization: data.inviterOrganization || '',
    });
    sendEvent('User Signed In');
    sendPageView(`${window.location.pathname}#signin-success`);

    // post their login
    let url = `https://${data.prefix}.${config.domain}/web/login_unified`;
    const anchorUrl = `#action=login&hash=${data.loginHash}`;

    // Do *NOT* add anchor on mobile app so it gets redirected properly to login screen
    if (!window.navigator.userAgent || window.navigator.userAgent.indexOf('ZebuApp') === -1) {
      url += anchorUrl;
    }

    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', url);

    const email = document.createElement('input');
    email.setAttribute('type', 'hidden');
    email.setAttribute('name', 'login');
    email.setAttribute('value', data.email);

    const password = document.createElement('input');
    password.setAttribute('type', 'hidden');
    password.setAttribute('name', 'password');
    password.setAttribute('value', data.passwordHash);

    const pin = document.createElement('input');
    pin.setAttribute('type', 'hidden');
    pin.setAttribute('name', 'pin');
    pin.setAttribute('value', data.pin ? data.pin : '');

    form.appendChild(email);
    form.appendChild(password);
    form.appendChild(pin);

    if (data.inviterOrganization) {
      // Below is to add action to get invited to an organization
      const action = document.createElement('input');
      action.setAttribute('type', 'hidden');
      action.setAttribute('name', 'action_after_login');
      action.setAttribute('value', `b2binvite:${data.inviterOrganization}`);
      form.appendChild(action);
    }

    document.body.appendChild(form);
    form.submit();
  },

  async resetPassword(data: ResetData): Promise<boolean> {
    const url = `https://${data.prefix}.${config.domain}/web/reset_password?token=${data.token}&db=${data.prefix}`;

    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', url);

    const email = document.createElement('input');
    email.setAttribute('type', 'hidden');
    email.setAttribute('name', 'email');
    email.setAttribute('value', data.email);

    const login = document.createElement('input');
    login.setAttribute('type', 'hidden');
    login.setAttribute('name', 'login');
    login.setAttribute('value', data.login);

    const name = document.createElement('input');
    name.setAttribute('type', 'hidden');
    name.setAttribute('name', 'name');
    name.setAttribute('value', data.firstName);

    const last_name = document.createElement('input');
    last_name.setAttribute('type', 'hidden');
    last_name.setAttribute('name', 'last_name');
    last_name.setAttribute('value', data.lastName);

    const password = document.createElement('input');
    password.setAttribute('type', 'hidden');
    password.setAttribute('name', 'password');
    password.setAttribute('value', data.passwordHash);

    const confirm_password = document.createElement('input');
    confirm_password.setAttribute('type', 'hidden');
    confirm_password.setAttribute('name', 'confirm_password');
    confirm_password.setAttribute('value', data.confirmPasswordHash);

    const csrf = document.createElement('input');
    csrf.setAttribute('type', 'hidden');
    csrf.setAttribute('name', 'csrf_token');
    csrf.setAttribute('value', '');

    form.appendChild(email);
    form.appendChild(name);
    form.appendChild(last_name);
    form.appendChild(login);
    form.appendChild(csrf);
    form.appendChild(password);
    form.appendChild(confirm_password);

    document.body.appendChild(form);
    return this.resetPasswordNew(data);
    // form.submit();
  },

  async resetPasswordNew(data: ResetData): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        const requestOptions: RequestInit = {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        };

        const body: URLSearchParams = new URLSearchParams();
        body.append('email', data.email);
        body.append('login', data.login);
        body.append('name', data.firstName);
        body.append('last_name', data.lastName);
        body.append('password', data.passwordHash);
        body.append('confirm_password', data.confirmPasswordHash);
        body.append('csrf_token', '');
        body.append('no_redirect', '1');

        const options = { ...requestOptions, body };
        const url = `https://${data.prefix}.${config.domain}/web/reset_password?token=${data.token}&db=${data.prefix}`;

        fetch(url, options).then((r) => {
          if (r.status === 500) {
            console.log('connectApi->resetPasswordNew: error returned from server');
            resolve(false);
          }
          window.location.href = `https://signup.${config.domain}`;

          resolve(true);
        });
      } catch (error) {
        console.log('connectApi->resetPasswordNew: reset pass error', error);
        resolve(false);
      }
    });
  },
};

export default Connect;
