import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CaptchaProvider } from '@developers/use-captcha';
import { Routes } from './Routes';
import { CAPTCHA_SITE_KEY } from './constants';

const App = (): JSX.Element => {
  return (
    <CaptchaProvider sitekey={CAPTCHA_SITE_KEY}>
      <Router>
        <Routes />
      </Router>
    </CaptchaProvider>
  );
};

export default App;
