import React, { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import './Building.css';
import ConfigManager from '../config';
import Base from '../pages/Base';
import { Headings } from '../styles';
import Banner from '../views/Banner';
import { Button } from '../tauria-react-components/particles/Button';

const { config } = new ConfigManager(window.location.origin);

const S = {
  wrapper: styled.div`
    display: grid;
    grid-template-columns: minmax(250px, 50%) 1fr;
    height: 100vh;
  `,
};

const VerifyEmail: FC = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [message, setMessage] = useState('Your email has been verified.');

  useEffect(() => {
    document.title = 'Verifying email - Tauria';
  }, []);

  useEffect(() => {
    fetch(`${config.backend.register}/v1/verify_email/${token}`)
      .then(async (res) => {
        if (res.ok) {
          const r = await res.json();
          console.log('verify_email fetch returned', res, r);
          switch (r.status) {
            case 'OK':
              setMessage(`Your email for organization ${r.prefix} has been verified.`);
              break;
            case 'ALREADY':
              setMessage(`Your email for organization ${r.prefix} was already verified.`);
              break;
            default:
              break;
          }
        }
        console.log('VerifyEmail: response not ok', res);
      })
      .catch((r) => {
        console.log('VerifyEmail: error', r);
      });
  }, [token, history]);

  const redirectToSignin = (): void => {
    history.replace('/');
  };

  return (
    <S.wrapper>
      <div>
        <Banner handleBack={(): void => console.log('return')} registerStep={-1} showBack={false} />
      </div>
      <div>
        <Base>
          <Headings>
            <h1>Email Verification</h1>
            <h2>{message}</h2>
          </Headings>
          <p>You can go to sign in now.</p>
          <Button
            disabled
            style={{ padding: '20px', marginTop: '80px' }}
            onClick={redirectToSignin}
            value="GO TO SIGN IN"
          />
        </Base>
      </div>
    </S.wrapper>
  );
};

export default VerifyEmail;
