import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import Logo from '../img/zebu_logo.svg';
import './Building.css';
import ConfigManager from '../config';
import analyticsApi from '../models/analyticsApi';

const { config } = new ConfigManager(window.location.origin);

const S = {
  cover: styled.div`
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(2, 8, 25, 0.9);
    backdrop-filter: blur(5px);
    position: absolute;
  `,
  centered: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  loadingFrame: styled.div`
    width: 300px;
    background-color: #f4f4f6;
    height: 10px;
    border-radius: 10px;
  `,
  loadingBar: styled.div`
    height: 10px;
    width: 100px;
    background-color: #1577fe;
    border-radius: 5px;
    animation: load 2s ease-in-out 0s forwards infinite;
    @keyframes load {
      0% {
        transform: translateX(0px);
      }
      50% {
        transform: translateX(200px);
      }
      100% {
        transform: translateX(0px);
      }
    }
  `,
};

interface OrgStatus {
  status: 'done' | 'building';
}

const BuildingOrg: React.FC = () => {
  const { token } = useParams();
  const history = useHistory();
  const [message, setMessage] = useState('Building Workspace');

  React.useEffect(() => {
    document.title = 'Building Workspace - Tauria';
  }, []);

  const signalBuilt = (): void => {
    const trackUser = async (): Promise<void> => {
      const campaignuuid = localStorage.getItem('@tauria-campaign-user_uuid');
      const prefix = localStorage.getItem('@tauria-campaign-prefix');
      const promotion = localStorage.getItem('@tauria-campaign-promotion');

      if (promotion !== null && prefix !== null) {
        await analyticsApi.trackOrgBuiltPage(promotion, prefix, true);
        localStorage.removeItem('@tauria-campaign-user_uuid');
        localStorage.removeItem('@tauria-campaign-prefix');
        localStorage.removeItem('@tauria-campaign-promotion');
      } else if (campaignuuid !== null && prefix !== null) {
        await analyticsApi.trackOrgBuiltPage(campaignuuid, prefix, false);
        localStorage.removeItem('@tauria-campaign-user_uuid');
        localStorage.removeItem('@tauria-campaign-prefix');
        localStorage.removeItem('@tauria-campaign-promotion');
      }
    };
    trackUser();
  };

  React.useEffect(() => {
    const interval = setInterval(async (): Promise<void> => {
      const res = await fetch(`${config.backend.register}/v1/done_building/${token}`);
      const data: OrgStatus = await res.json();
      if (data.status === 'done') {
        signalBuilt();
        clearInterval(interval);
        setMessage('Finished! Redirecting into organization');
        setTimeout(() => {
          console.log('BuildingOrg: props timeout', history.location.state);
          history.replace('/', history.location.state);
        }, 3000);
      }
    }, 2000);

    return (): void => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [token, history]);

  return (
    <div className="preload-full-container">
      <div className="preload-sidebar">
        <div className="preload-sidebar-icon-top" />
        <div className="preload-sidebar-background blue">
          <div className="preload-sidebar-icon" />
        </div>
        <div className="preload-sidebar-icon" />
        <div className="preload-sidebar-icon" />
      </div>
      <div className="preload-sub-sidebar">
        <div className="preload-profile">
          <div className="preload-profile-image" />
          <div className="preload-profile-status" />
          <div className="preload-adjacent">
            <div className="preload-name" />
            <div className="preload-handle" />
          </div>
        </div>
        <div className="preload-sub-sidebar-container">
          <div className="preload-search" />
          <div className="preload-tab" />
          <div className="preload-discussion">
            <div className="preload-team-name" />
            <div className="preload-keyline" />
            <div className="preload-chat">
              <div className="preload-chat-image" />
              <div className="preload-chat-name" />
            </div>
            <div className="preload-chat">
              <div className="preload-chat-image" />
              <div className="preload-chat-name" />
            </div>
            <div className="preload-chat">
              <div className="preload-chat-image" />
              <div className="preload-chat-name" />
            </div>
            <div className="preload-chat">
              <div className="preload-chat-image" />
              <div className="preload-chat-name" />
            </div>
            <div className="preload-chat">
              <div className="preload-chat-image" />
              <div className="preload-chat-name" />
            </div>
            <div className="preload-chat">
              <div className="preload-chat-image" />
              <div className="preload-chat-name" />
            </div>
          </div>
        </div>
      </div>
      <div className="preload-main">
        <div className="preload-top">
          <div className="preload-channel-info preload-mobile-hide">
            <div className="preload-channel-name" />
            <div className="preload-channel-description" />
          </div>
          <div className="preload-top-search" />
        </div>
        <div className="preload-channel-details">
          <div className="preload-channel-detail-head">
            <div className="preload-channel-image" />
            <div className="preload-channel-info">
              <div className="preload-channel-name" />
              <div className="preload-channel-description" />
            </div>
          </div>
          <div className="preload-channel-detail-welcome">
            <div className="preload-welcome-title" />
            <div className="preload-welcome-start" />
          </div>
        </div>
        <div className="preload-content">
          <div className="preload-card preload-card-first">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg" />
            </div>
          </div>
          <div className="preload-card">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg preload-long" />
            </div>
          </div>
          <div className="preload-card">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg preload-tall" />
            </div>
          </div>
          <div className="preload-card">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg preload-short" />
            </div>
          </div>
          <div className="preload-card">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg preload-long" />
              <div className="preload-card-msg" />
            </div>
          </div>
          <div className="preload-card">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg" />
            </div>
          </div>
          <div className="preload-card">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg preload-short" />
            </div>
          </div>
          <div className="preload-card">
            <div className="preload-card-image" />
            <div className="preload-card-content">
              <div className="preload-card-header">
                <div className="preload-card-name" />
                <div className="preload-card-time" />
              </div>
              <div className="preload-card-msg" />
            </div>
          </div>
        </div>
        <div className="preload-composer">
          <div className="preload-composer-button preload-composer-start" />
          <div className="preload-composer-button preload-composer-start" />
          <div className="preload-composer-text preload-composer-start" />
          <div className="preload-composer-end">
            <div className="preload-composer-button" />
            <div className="preload-composer-button preload-compsoer-last" />
          </div>
        </div>
      </div>
      <S.cover />
      <S.centered>
        <img style={{ margin: '20px auto', width: '300px' }} src={Logo} alt="logo" />
        <p style={{ color: 'white', textAlign: 'center' }}>{message}</p>
        <S.loadingFrame>
          <S.loadingBar />
        </S.loadingFrame>
      </S.centered>
    </div>
  );
};

export default BuildingOrg;
