import React from 'react';
import styled, { CSSObject } from 'styled-components';

import { LayoutProps, LayoutS } from './Layout';
import Button from '../particles/Button';

import defaultRenderer from './FormRenderers/Default';

export interface FormInputOptions {
  name: string;
  type: 'text' | 'password' | 'email' | 'radio' | 'button';
  area: string;
  autoFocus?: boolean;
  headingStyle?: CSSObject;
  limit?: number;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
interface InputOptions extends FormInputOptions {
  renderer?: any;
  showEye?: boolean;
  startIcon?: string;
  label?: string;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const AllErrorField = styled.p`
  color: #f15c5a;
  margin-top: 0px;
  margin-bottom: 5px;
  animation: growin 0.2s linear 0s forwards 1;
  @keyframes growin {
    from {
      height: 0px;
    }
    to {
      height: 19.4px;
    }
  }
`;

interface FormProps extends LayoutProps {
  inputs: InputOptions[];
  errors: any;
  submit: InputOptions;
  onSubmit: (arg0: any) => void;
  onChange?: (arg0: any) => void;
  renderer?: any;
  headingStyle?: CSSObject;
}

const isKeyboardEvent = (event: any): event is KeyboardEvent => {
  if (event.charcode) return true;
  return false;
};

const Form: React.FC<FormProps> = ({
  rows,
  colms,
  areas,
  inputs,
  errors,
  submit,
  onSubmit,
  onChange,
  renderer,
  headingStyle,
}): any => {
  const ref: any = React.useRef<any>(undefined);

  const handleSubmit = (e: any): void => {
    e.preventDefault();
    const values: any = {};
    if (isKeyboardEvent(e)) {
      if (e.charCode !== 13) {
        return;
      }
    }
    if (ref.current) {
      inputs.forEach((input) => {
        const element: HTMLInputElement | null = document.querySelector(`#${input.area}`);
        if (element) {
          values[input.name] = element.value;
        }
      });
    }
    onSubmit(values);
  };

  const handleChange = React.useCallback(() => {
    const values: Record<string, string> = {};
    if (ref.current) {
      inputs.forEach((input) => {
        const element: HTMLInputElement | null = document.querySelector(`#${input.area}`);
        if (element) {
          values[input.name] = element.value;
        }
      });
    }
    onChange && onChange(values);
  }, [onChange, ref.current, inputs]);

  return [
    errors.ALL ? <AllErrorField key="error message">{errors.ALL}</AllErrorField> : '',
    <form key="form" onSubmit={handleSubmit}>
      <LayoutS ref={ref} rows={rows} colms={colms} areas={areas} gap="0em 1em">
        {inputs.map((input, key) => {
          const inputWithStyles = headingStyle ? { ...input, headingStyle } : input;
          return input.renderer
            ? input.renderer(inputWithStyles, key, errors, handleChange)
            : renderer
              ? renderer(inputWithStyles, key, errors, handleChange)
              : defaultRenderer(inputWithStyles, key, errors, handleChange);
        })}
        <Button
          onClick={handleSubmit}
          onKeyPress={handleSubmit}
          value={submit.name}
          style={{ marginTop: '20px', gridArea: submit.area }}
          width="100%"
        />
      </LayoutS>
    </form>,
  ];
};

export default Form;
