import styled from 'styled-components';

export const Headings = styled.header`
  margin: 0 0 2rem;
  width: calc(100% - 2rem);

  @media only screen and (min-width: 900px) {
    margin: 0 0 3rem;
    width: calc(100% - 10rem);
  }

  @media only screen and (min-width: 1200px) {
    margin: 0 0 3rem;
  }

  @media only screen and (max-height: 700px) {
    margin: 0 0 1rem;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    line-height: calc(59 / 48);
    color: #0a132a;
    margin: 0 0 0.5rem;
    font-size: 2rem;

    @media only screen and (min-width: 900px) {
      margin: 0 0 1rem;
      font-size: 2.25rem;
    }

    @media only screen and (min-width: 1200px) {
      margin: 0 0 1rem;
      font-size: 3rem;
    }
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    line-height: calc(44 / 36);
    color: #3c4b71;
    margin: 0;
    font-size: 1.5rem;
    overflow-wrap: break-word;

    @media only screen and (min-width: 900px) {
      font-size: 1.75rem;
    }

    @media only screen and (min-width: 1200px) {
      font-size: 2.25rem;
    }
  }
`;

export const FormTopSpacer = styled.div`
  margin-top: 0;

  @media only screen and (min-width: 900px) and (min-height: 500px) {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 900px) and (min-height: 600px) {
    margin-top: 2rem;
  }

  @media only screen and (min-width: 900px) and (min-height: 700px) {
    margin-top: 3rem;
  }

  @media only screen and (min-width: 900px) and (min-height: 800px) {
    margin-top: 4rem;
  }

  @media only screen and (min-width: 900px) and (min-height: 900px) {
    margin-top: 5rem;
  }
`;
