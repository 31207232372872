import React from 'react';
import styled, { CSSObject } from 'styled-components';
import ColorManager from '../extras/Colors';
import EmailIcon from '../extras/img/emailIcon.svg';
import EyeOpenIcon from '../extras/img/eyeIcon.svg';
import EyeOffIcon from '../extras/img/eyeIconOff.svg';
import LockIcon from '../extras/img/lockIcon.svg';

const normalInput = `
background: #ffffff;
  border: 1px solid #E4E7EC;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 9px 9px;
  width: 100%;
  font-size: 20px;
  &[type='checkbox'] {
    width: auto;
  }
  &:read-only {
    background-color: #F7F8F9;
    color: #191a21;
    padding: 9px 9px;
    font-size: 20px;

    border: 1px solid #E4E7EC;
    border-radius: 5px;
  }
  &:focus {
    outline: none;
  }
  &:focus:not(:read-only) {
    border: solid 1px ${new ColorManager().default};
    border-left: solid 3px ${new ColorManager().default};
  }
  transition: border 0.2s;
`;

const plainInput = `
  background-color: transparent;
  color: #6E7D97;
  border: 0px solid transparent;
  padding: 9px 9px;
  width: calc(100% - 18px);
  font-size: 20px;
  outline: none;
`;

export const Input = styled.input<{ width?: string; textonly?: boolean; error?: boolean }>`
  ${(p): string => (!p.textonly ? normalInput : plainInput)}
  width: ${(p): string => (!p.textonly && p.width ? p.width : 'calc(100% - 18px)')};
  ${(p): string =>
    p.error
      ? `
    border-color: #F15C5A;
    animation: error .3s linear 0s forwards;
  `
      : ''}
  @keyframes error {
    0% {transform: translateX(5px)}
    25% {transform: translateX(-5px)}
    50% {transform: translateX(5px)}
    75% {transform: translateX(-5px)}
    100% {transform: translateX(0px)}
  }
`;

const ImageInputS = styled.div<{ src: string; width?: string }>`
  background-color: #f1f2f2;
  border-radius: 5px;
  padding: 0px 0px 0px 34px;
  background-image: url(${(p): string => (p ? p.src : '')});
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  width: ${(p): string => (p.width ? p.width : 'calc(100% - 34px)')};
  @media (max-width: 599px) {
    width: 92%;
  }
`;

interface InputProps {
  value?: string;
  placeholder?: string;
  width?: string;
  ref?: any;
  textonly?: boolean;
  style?: CSSObject;
  id?: string;
  headingStyle?: CSSObject;
  error?: boolean;
  onChange?: any;
  readOnly?: boolean;
  autoFocus?: boolean;
  limit?: number;
}

interface TypedInputProps extends InputProps {
  type: 'text' | 'radio' | 'email' | 'password' | 'button';
}

interface InputPasswordProps extends InputProps {
  showEye?: boolean;
  startIcon?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => any;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface LabeledInputPasswordProps extends InputPasswordProps {
  label: string;
}
interface LabeledInputProps extends TypedInputProps {
  label: string;
}

interface ImageInputProps extends TypedInputProps {
  src: string;
}

const H3 = styled.h3`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 3px;
  color: #6e7d97;
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25;
  margin: 1rem 0 0.5rem;
  color: #6e7d97;
`;

export const LabeledInput: React.FC<LabeledInputProps> = ({
  value,
  placeholder,
  width,
  ref,
  label,
  style,
  headingStyle,
  id,
  type,
  error,
  onChange,
  readOnly,
  autoFocus = false,
  limit,
}) => {
  return (
    <div>
      <Label htmlFor={id} style={headingStyle}>
        {label}
      </Label>
      <Input
        type={type}
        width={width}
        style={style}
        ref={ref}
        value={value}
        placeholder={placeholder}
        id={id}
        error={error}
        onChange={onChange}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={limit}
      />
    </div>
  );
};

export const LabeledImageInput: React.FC<LabeledInputProps & ImageInputProps> = ({
  value,
  placeholder,
  width,
  ref,
  label,
  style,
  headingStyle,
  id,
  type,
  src,
  error,
}) => {
  return (
    <div>
      <H3 style={headingStyle}>{label}</H3>
      <div>
        <img src={src} alt="Input Icon" />
        <Input
          type={type}
          width={width}
          style={style}
          ref={ref}
          value={value}
          placeholder={placeholder}
          id={id}
          textonly
          error={error}
        />
      </div>
    </div>
  );
};

export const ImageInput: React.FC<ImageInputProps> = ({ value, src, placeholder, width, ref }) => {
  return (
    <ImageInputS src={src} width={width}>
      <Input ref={ref} value={value} placeholder={placeholder} />
    </ImageInputS>
  );
};

export const ImageInputRO: React.FC<ImageInputProps> = ({ value, src, width, ref, textonly }) => {
  return (
    <ImageInputS src={src} width={width}>
      <Input ref={ref} value={value} readOnly textonly={!!textonly} />
    </ImageInputS>
  );
};

const EP = {
  container: styled.div<{ focused: boolean; error: boolean }>`
    width: calc(100% - 36px);
    display: flex;
    align-items: center;
    border: 1px solid #d3d8e0;
    border-radius: 5px;
    padding: 0px 18px;
    transition: border 0.2s;
    ${(p): string =>
      p.focused
        ? `
    border-color: #1577FE;
    `
        : ''}

    ${(p): string =>
      p.error
        ? `
    border-color: #F15C5A;
    `
        : ''}
  `,
  keyline: styled.div<{ focused: boolean; error: boolean }>`
    height: 30px;
    border-left: solid 1px #d3d8e0;
    margin: 0px 18px;
    transition: border 0.2s;
    ${(p): string =>
      p.focused
        ? `
    border-left: solid 1px #1577FE;
    `
        : ''}

    ${(p): string =>
      p.error
        ? `
    border-left: solid 1px #F15C5A;
    `
        : ''}
  `,
};

export const LabeledEPInput: React.FC<LabeledInputProps> = ({
  value,
  placeholder,
  width,
  ref,
  label,
  style,
  headingStyle,
  id,
  type,
  onChange,
  autoFocus = false,
  error,
}) => {
  const [focused, setFocus] = React.useState(false);

  return (
    <div>
      <Label htmlFor={id} style={headingStyle}>
        {label}
      </Label>
      <EP.container error={!!error} focused={focused}>
        <img alt={type} src={type === 'email' ? EmailIcon : LockIcon} />
        <EP.keyline error={!!error} focused={focused} />
        <Input
          type={type}
          width={width}
          style={{ ...style, color: 'black' }}
          ref={ref}
          value={value}
          autoFocus={autoFocus}
          placeholder={placeholder}
          id={id}
          error={error}
          textonly
          onChange={onChange}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      </EP.container>
    </div>
  );
};

const ULIS = {
  container: styled.div`
    position: relative;
  `,
  indicator: styled.div`
    position: absolute;
    text-align: right;
    bottom: 13px;
    right: 13px;
    text-transform: uppercase;
    color: #808285;
  `,
};

export const UrlLabeledInput: React.FC<LabeledInputProps & { url: string }> = ({
  label,
  type,
  width,
  style,
  headingStyle,
  ref,
  value,
  placeholder,
  id,
  error,
  url,
}) => {
  return (
    <ULIS.container>
      <H3 style={headingStyle}>{label}</H3>
      <Input
        type={type}
        width={width}
        style={style}
        ref={ref}
        value={value}
        placeholder={placeholder}
        id={id}
        error={error}
      />
      <ULIS.indicator>{url}</ULIS.indicator>
    </ULIS.container>
  );
};

const getColor = (width: number, breakpoints: number[]): string =>
  width <= breakpoints[0]
    ? '#FD5350'
    : width < breakpoints[2]
    ? '#FEC243'
    : width <= breakpoints[2]
    ? '#00BA07'
    : '#00BA07';

const getDiscriptor = (width: number, breakpoints: number[]): string =>
  width <= breakpoints[0]
    ? 'Weak Password'
    : width < breakpoints[2]
    ? 'Moderate Password'
    : width >= breakpoints[2]
    ? 'Strong Password'
    : 'Strong Password';

const getWidth = (width: number, steps: number): number => (width * 100) / steps;

const PSI = {
  strengthContainer: styled.div`
    margin-top: 10px;
    height: 3px;
    width: 100%;
    background-color: #f7f7f8;
  `,
  strengthBar: styled.div<{ width: number; break: number[]; steps: number }>`
    height: 3px;
    width: ${(p) => getWidth(p.width, p.steps)}%;
    background-color: ${(p): string => getColor(p.width, p.break)};
    transition: width 0.2s;
  `,
  strengthDescription: styled.div<{ width: number; break: number[] }>`
    margin-top: 5px;
    color: ${(p): string => getColor(p.width, p.break)};
    width: 100%;
    text-align: right;
    &::before {
      display: inline-block;
      margin-right: 10px;
      content: '';
      width: 13px;
      height: 13px;
      background-color: ${(p): string => getColor(p.width, p.break)};
      border-radius: 50%;
    }
  `,
  popup: styled.div`
    position: absolute;
    right: 0px;
    top: -120px;
    background-color: #0a132a; /* fallback */
    background-color: #0a132af0;
    border-radius: 5px;
    padding: 14px;
    animation: fade-in 0.2s ease-out 0s forwards;
    & > p:first-child {
      margin-top: 0px;
    }
    & > p:last-child {
      border-bottom: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    @keyframes fade-in {
      from {
        opacity: 0;
        transform: translateY(1rem);
      }
      to {
        opacity: 1;
        transform: translateY(0rem);
      }
    }
  `,
  popupLabel: styled.p<{ done: boolean }>`
    color: ${(p): string => (p.done ? '#35C849' : 'white')};
    font-size: 12px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    padding-bottom: 10px;
    &::before {
      display: inline-block;
      content: '';
      background-color: ${(p): string => (p.done ? '#35C849' : 'white')};
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
  `,
};

export const PasswordStrengthInput: React.FC<
  LabeledInputProps & { criteria: { done: boolean; label: string; hidden: boolean }[] }
> = ({
  label,
  width,
  style,
  headingStyle,
  ref,
  value,
  placeholder,
  id,
  autoFocus = false,
  error,
  onChange,
  criteria,
}) => {
  const [showPopup, setShowPopup] = React.useState(false);

  let progress = 0;
  const steps = 3;
  const breakpoint = Math.round(criteria.length / steps);
  const breakpoints = [breakpoint, breakpoint * 2, breakpoint * 3];
  criteria.forEach((check) => {
    if (check.done) {
      progress += 1;
    }
  });

  return (
    <div style={{ position: 'relative' }}>
      <Label htmlFor={id} style={headingStyle}>
        {label}
      </Label>
      <PasswordInput
        width={width}
        style={style}
        ref={ref}
        value={value}
        placeholder={placeholder}
        id={id}
        showEye
        error={error}
        autoFocus={autoFocus}
        onChange={onChange}
        onFocus={() => {
          setShowPopup(true);
        }}
        onBlur={() => {
          setShowPopup(false);
        }}
      />
      {progress > 0 ? (
        <div>
          <PSI.strengthContainer>
            <PSI.strengthBar width={progress} break={breakpoints} steps={criteria.length} />
          </PSI.strengthContainer>
          <PSI.strengthDescription width={progress} break={breakpoints}>
            {getDiscriptor(progress, breakpoints)}
          </PSI.strengthDescription>
        </div>
      ) : (
        <div style={{ height: '37px' }} /> // Height of the Strength indicator
      )}
      {showPopup ? (
        <PSI.popup>
          {criteria.reduce((acc: JSX.Element[], crit) => {
            if (crit.hidden === false) {
              acc.push(<PSI.popupLabel done={crit.done}>{crit.label}</PSI.popupLabel>);
            }
            return acc;
          }, [])}
        </PSI.popup>
      ) : (
        ''
      )}
    </div>
  );
};

const PasswordInputStyle = {
  container: styled(EP.container)<{ showIconEnd: boolean; showIconStart: boolean }>`
    ${(p): string => {
      let outstring = '';
      if (p.showIconEnd && p.showIconStart) {
        outstring += 'padding-right: 0;';
        outstring += 'width: calc(100% - 18px)';
      } else {
        outstring += 'padding: 0;';
        outstring += 'width: 100%';
      }

      return outstring;
    }}
  `,
};

export const PasswordInput: React.FC<InputPasswordProps> = ({
  width,
  style,
  ref,
  value,
  placeholder,
  id,
  onFocus,
  onBlur,
  onChange,
  startIcon = '',
  showEye = false,
  autoFocus = false,
  onEnterPressed,
  error,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [focused, setFocus] = React.useState(false);

  const toggle = (): void => setShowPassword(!showPassword);

  return (
    <PasswordInputStyle.container
      showIconEnd={showEye}
      showIconStart={!!startIcon}
      error={!!error}
      focused={focused}
    >
      {startIcon && (
        <>
          <img alt="reveal password" src={startIcon} />
          <EP.keyline error={!!error} focused={focused} />
        </>
      )}
      <Input
        type={showPassword ? 'text' : 'password'}
        width={width}
        style={{ ...style, color: 'black' }}
        ref={ref}
        value={value}
        placeholder={placeholder}
        id={id}
        onChange={onChange}
        onKeyPress={
          onEnterPressed || showEye
            ? (event): void => {
                if (event.charCode === 13) {
                  event.preventDefault();
                  if (onEnterPressed) {
                    onEnterPressed(event);
                  }
                }
              }
            : undefined
        }
        error={error}
        autoFocus={autoFocus}
        textonly
        onFocus={(event) => {
          setFocus(true);
          if (onFocus) {
            onFocus(event);
          }
        }}
        onBlur={(event) => {
          setFocus(false);
          if (onBlur) {
            onBlur(event);
          }
        }}
      />
      {showEye && (
        <div
          tabIndex={-1}
          role="button"
          onKeyPress={(event: React.KeyboardEvent<HTMLElement>): void => {
            event.preventDefault();
            toggle();
          }}
          style={{ marginRight: '18px', outline: 'none', height: '18px', width: '18px' }}
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
            event.preventDefault();
            toggle();
          }}
        >
          <img
            alt={`${showPassword ? 'Show password' : 'Hide password'} eye icon`}
            src={showPassword ? EyeOpenIcon : EyeOffIcon}
          />
        </div>
      )}
    </PasswordInputStyle.container>
  );
};
export const LabeledPasswordInput: React.FC<LabeledInputPasswordProps> = ({
  label,
  width,
  style,
  ref,
  value,
  placeholder,
  headingStyle,
  id,
  startIcon = '',
  showEye = false,
  onEnterPressed,
  error,
}) => {
  return (
    <div>
      <H3 style={headingStyle}>{label}</H3>
      <PasswordInput
        width={width}
        style={style}
        ref={ref}
        value={value}
        placeholder={placeholder}
        id={id}
        startIcon={startIcon}
        showEye={showEye}
        onEnterPressed={onEnterPressed}
        error={error}
      />
    </div>
  );
};

export const ErrorField = styled.p`
  color: #f15c5a;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;
