/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useCaptcha } from '@developers/use-captcha';
import { useHistory } from 'react-router-dom';
import Banner from '../views/Banner';
import Signin from '../views/Signin';
import { PasswordModal, TwoFactorModal, B2BPassphraseModal } from '../views/PasswordModal';
import OrgList, { OrgListInterface, Organization } from '../views/OrgList';
import authApi from '../models/authApi';
import analyticsApi from '../models/analyticsApi';
import Connect, { SignupData } from '../models/connectApi';
import checker, { Result } from '../utilities/validate';
import Encryption from '../utilities/encryption';
import Base from './basePage';
import { LoadingSync } from '../views/Loading';

interface SigninState {
  step: 'signin' | 'orgList' | 'invalidB2BToken' | 'loading' | 'passphrase';
  showVerifyPassword: boolean;
  email: string;
  password: string;
  loginHash: string;
  orgList: OrgListInterface;
  inviterOrganization: string;
  passphraseCrypt: boolean;
}

const Homepage: React.FC<{ b2b: boolean }> = ({ b2b }) => {
  const [state, setState] = useState<SigninState>({
    step: b2b ? 'loading' : 'signin',
    showVerifyPassword: false,
    email: '',
    password: '',
    loginHash: '',
    inviterOrganization: '',
    passphraseCrypt: true,
    orgList: { orgList: [] },
  });
  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>(undefined);
  const [selectedOrg2FA, setSelectedOrg2FA] = useState<Organization | undefined>(undefined);
  const [askPassphrase, setAskPassphrase] = useState(false);
  const recaptcha = useCaptcha();
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  const history = useHistory();
  const [loading, setLoading] = React.useState<{ isLoading: boolean; message: string }>({
    isLoading: false,
    message: '',
  });

  const campaignuuid = query.get('campaign');
  if (campaignuuid !== null) {
    analyticsApi.trackRegisterPage(campaignuuid);
    localStorage.setItem('@tauria-campaign-user_uuid', campaignuuid);
  }

  React.useEffect(() => {
    document.title = 'Sign In - Tauria';

    return function cleanup(): void {
      // reset history state
      history.replace({ ...history.location, state: undefined });
    };
  }, [history]);

  const b2bPassphraseClose = (): void => {
    if (!state.passphraseCrypt) {
      setAskPassphrase(false);
      setState({
        ...state,
        step: 'signin',
      });
      return;
    }
    setState({
      ...state,
      step: 'invalidB2BToken',
    });
    setAskPassphrase(false);
  };

  const prepareRedirect = (
    org: Organization,
    email: string,
    passwordHash: string,
    loginHash: string,
    inviterOrganization: string | null,
    pin?: string,
  ): void => {
    const loginData: SignupData = {
      email,
      passwordHash,
      prefix: org.organization.orgName,
      loginHash,
      inviterOrganization,
      pin,
    };
    Connect.login(loginData);
  };

  const isError = (e: any): e is Error => {
    if (e.stack) return true;
    return false;
  };

  const handleOrgClick = (org: Organization): void => {
    if (!org.isAuthenticated) {
      setSelectedOrg(org);
    } else if (org.twofa_required !== 'false') {
      setSelectedOrg2FA(org);
    } else {
      const { email, password } = state;
      prepareRedirect(
        org,
        email,
        Encryption.encryptPassword(password),
        Encryption.getLoginHash(password),
        b2b && !state.passphraseCrypt ? state.inviterOrganization : null,
      );
    }
  };

  const doAuthLogin = async (
    email: string,
    passwordHash: string,
    passwordHashLegacy: string,
    targetOrgGuid?: string,
  ): Promise<{ error: string } | OrgListInterface> => {
    let captcha = '';
    // handle captcha on the server side
    try {
      captcha = await recaptcha.execute('get_users_orgs');
    } catch (e) {
      if (e === null) console.error('No token retrieved');
    }

    let result;

    let response = await authApi.getUserOrgs(email, passwordHash, captcha, undefined);
    if (response.status === 403) {
      try {
        captcha = await recaptcha.execute('get_users_orgs');
      } catch (e) {
        if (e === null) console.error('No token retrieved');
      }
      const respData = await response.json();
      console.log('Home: get user orgs 403', respData);
      // this is for password migrations
      response = await authApi.getUserOrgs(email, passwordHash, captcha, passwordHashLegacy);
    } else if (targetOrgGuid && !isError(response) && response.status === 200) {
      // check if desired org is authenticated
      result = await response.json();
      console.log('Home: get user orgs 200', result);

      if ('orgList' in result) {
        // let orgList = data;
        const org = result.orgList.find(
          (maybeOrg: Organization) => maybeOrg.organization.orgGuid === targetOrgGuid,
        );
        if (!org || !org.isAuthenticated) {
          result = undefined;
          response = await authApi.getUserOrgs(email, passwordHash, captcha, passwordHashLegacy);
        }
      }
    }

    if (isError(response)) {
      console.error('Error logging in: ', await response.json());
      return { error: Result.GeneralError };
    }
    if (response.status === 403) {
      return { error: Result.UserDoesntExist };
    }
    if (response.status === 500) {
      return { error: Result.GeneralError };
    }

    return result || response.json();
  };

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<{ error: string } | undefined> => {
    try {
      const passwordHash = Encryption.encryptPassword(password);
      const passwordHashLegacy = Encryption.encryptPasswordLegacy(password);
      const loginHash = Encryption.getLoginHash(password);
      const processedEmail = String(email).trim().toLowerCase();

      const data = await doAuthLogin(processedEmail, passwordHash, passwordHashLegacy);
      if ('error' in data) {
        return data;
      }

      // clean up history state
      history.replace({ ...history.location, state: undefined });
      if (data.orgList && data.orgList.length > 1) {
        setLoading({ isLoading: false, message: '' });
        setState({
          ...state,
          step: 'orgList',
          orgList: data,
          email: processedEmail,
          password,
          loginHash,
        });
        // only one org, auto login
      } else if (data.orgList && data.orgList.length === 1 && data.orgList[0].isAuthenticated) {
        if (data.orgList[0].twofa_required === 'false') {
          prepareRedirect(
            data.orgList[0],
            processedEmail,
            passwordHash,
            loginHash,
            b2b && !state.passphraseCrypt ? state.inviterOrganization : null,
          );
        } else {
          setLoading({ isLoading: false, message: '' });
          setState({
            ...state,
            orgList: data,
            email: processedEmail,
            password,
            loginHash,
          });
          setSelectedOrg2FA(data.orgList[0]);
        }
      } else {
        return { error: Result.UserDoesntExist };
      }
      return undefined;
    } catch (e) {
      return { error: Result.GeneralError };
    }
  };

  const historyState: any = history.location.state ? history.location.state : '';
  if (historyState && historyState.passHash && historyState.email) {
    const { email, passHash } = historyState;
    console.log('Home: history', email, passHash);

    if (!loading.isLoading) {
      setLoading({ isLoading: true, message: 'Preparing to sign-in into organization...' });
    }
    handleLogin(email, passHash);
  }

  const handleTokenVerification = async (): Promise<void> => {
    setState({
      ...state,
      step: 'invalidB2BToken',
    });
    if (token === null) {
      return;
    }
    try {
      const response = await authApi.checkB2BToken(token);
      if (response) {
        // Since we receive in the form of weblakes.zebu.io we only want the orgname.
        const orgName = response.organization.substr(0, response.organization.indexOf('.'));
        if (!response.passphrase_crypt) {
          // This means we don't need the invite password, sign in directly
          setState({
            ...state,
            step: 'signin',
            inviterOrganization: orgName,
            passphraseCrypt: false,
          });
          return;
        } // else we will require the password
        if (!state.passphraseCrypt) return;
        setAskPassphrase(true);
        setState({
          ...state,
          step: 'passphrase',
          inviterOrganization: orgName,
          passphraseCrypt: true,
        });
        return;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const doPassphraseCheck = async (
    passphraseHash: string,
    passphraseHashLegacy: string,
  ): Promise<{ error: string } | string> => {
    let result;
    if (token === null) {
      return { error: Result.InvalidToken };
    }
    const response = await authApi.validateB2BPassphrase(
      token,
      passphraseHash,
      passphraseHashLegacy,
    );
    if (response.status === 403) {
      return { error: Result.IncorrectPassphrase };
    }

    if (response.status === 200) {
      result = await response.json();
      if (result.passphrase_verified) {
        return 'PASSPHRASE_VERIFIED';
      }
      return { error: Result.IncorrectPassphrase };
    }

    if (isError(response)) {
      return { error: Result.GeneralError };
    }
    if (response.status === 403) {
      return { error: Result.UserDoesntExist };
    }
    if (response.status === 500) {
      return { error: Result.GeneralError };
    }
    return { error: Result.IncorrectPassphrase };
  };

  const handlePassphrase = async (passphrase: string): Promise<{ error: string } | string> => {
    const passphraseHash = Encryption.encryptPassword(passphrase);
    const passphraseHashLegacy = Encryption.encryptPasswordLegacy(passphrase);
    const data = await doPassphraseCheck(passphraseHash, passphraseHashLegacy);

    if (data !== 'PASSPHRASE_VERIFIED') {
      // Incorrect Passphrase
      return data;
    }
    // perform page changing to sign in since passphrase is verified
    setState({
      ...state,
      step: 'signin',
    });
    return data;
  };

  const getBackState = (currentState: SigninState): 'signin' | 'orgList' | undefined => {
    if (currentState.step === 'orgList') {
      return 'signin';
    }
    return undefined;
  };

  const handleBack = (): void => {
    const newState = { ...state };
    const step = getBackState(newState);
    if (step) {
      newState.step = step;
      setState(newState);
    }
  };

  const handle2FAModalVerify = async ({
    CODE: code,
  }: {
    CODE: string;
  }): Promise<undefined | { error: string }> => {
    if (!selectedOrg2FA) {
      return undefined;
    }
    if (!checker.checkTwoFactorCode(code)) {
      return { error: 'Invalid Code Format' };
    }

    const { email } = state;
    const prefix = selectedOrg2FA.organization.orgName;

    try {
      let captcha = '';
      // handle captcha on the server side
      try {
        captcha = await recaptcha.execute('verify_2fa');
      } catch (e) {
        if (e === null) console.error('No token retrieved');
      }
      const result = await authApi.validate2FA(email, prefix, code, captcha);
      if (result) {
        prepareRedirect(
          selectedOrg2FA,
          state.email,
          Encryption.encryptPassword(state.password),
          state.loginHash,
          b2b && !state.passphraseCrypt ? state.inviterOrganization : null,
          code,
        );
      } else {
        return { error: 'Invalid Code' };
      }
    } catch (e) {
      console.error(e);
    }
    return undefined;
  };

  const handlePasswordModalAuth = async ({
    PASSWORD: password,
  }: {
    PASSWORD: string;
  }): Promise<undefined | { error: string }> => {
    const targetOrg = selectedOrg;
    if (!targetOrg) {
      return undefined;
    }
    const passwordHash = Encryption.encryptPassword(password);
    const passwordHashLegacy = Encryption.encryptPasswordLegacy(password);
    const loginHash = Encryption.getLoginHash(password);
    const result = await doAuthLogin(
      state.email,
      passwordHash,
      passwordHashLegacy,
      targetOrg.organization.orgGuid,
    );

    if ('orgList' in result) {
      if (
        result.orgList &&
        result.orgList.findIndex(
          (org) =>
            org.organization.orgGuid === targetOrg.organization.orgGuid && org.isAuthenticated,
        ) >= 0
      ) {
        setSelectedOrg(undefined);
        if (targetOrg.twofa_required !== 'false') {
          setState({
            ...state,
            password,
            loginHash,
          });
          setSelectedOrg2FA(targetOrg);
        } else {
          setLoading({ isLoading: true, message: 'Signing in...' });
          prepareRedirect(
            targetOrg,
            state.email,
            passwordHash,
            loginHash,
            b2b && !state.passphraseCrypt ? state.inviterOrganization : null,
          );
        }
      } else {
        return { error: 'Invalid Password' };
      }
    } else {
      return result;
    }
    return undefined;
  };

  const handleB2BPassphraseModalVerify = async ({
    CODE: code,
  }: {
    CODE: string;
  }): Promise<undefined | { error: string }> => {
    if (!askPassphrase) {
      return undefined;
    }

    try {
      const result = await handlePassphrase(code);
      if (typeof result === 'string') {
        if (result === 'PASSPHRASE_VERIFIED') {
          setAskPassphrase(false);
          setState({
            ...state,
            step: 'signin',
            passphraseCrypt: false,
          });
        }
        return undefined;
      }
      return result;
    } catch (e) {
      console.error(e);
    }
    return undefined;
  };

  const currentScreen = (): JSX.Element => {
    if (state.step === 'loading' || state.step === 'passphrase') {
      if (state.step === 'loading') handleTokenVerification();
      return (
        <div>
          <h2 style={{ padding: '10px 80px 0px', fontSize: '3rem' }}>
            Waiting For Authentication...
          </h2>
        </div>
      );
    }

    if (state.step === 'invalidB2BToken') {
      return (
        <div>
          <h2 style={{ padding: '50px 80px 0px', fontSize: '3rem', marginBottom: '-40px' }}>
            Invalid Token or Passphrase
          </h2>
          <p style={{ padding: '50px 80px 0px', fontSize: '1.5rem', marginBottom: '-40px' }}>
            It is also possibe that the token expired, please request a new token. Please ask the
            inviter for the correct Passphrase.
          </p>
        </div>
      );
    }

    if (state.step === 'signin') {
      return (
        <div
          key="AppSignin"
          style={{
            gridArea: 'form',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          {b2b && (
            <h2 style={{ padding: '50px 80px 0px', fontSize: '3rem', marginBottom: '-40px' }}>
              B2B Connection
            </h2>
          )}
          <Signin isNextStage={!!selectedOrg2FA} onLogin={handleLogin} />
        </div>
      );
    }

    if (state.step === 'orgList') {
      return <OrgList key="OrgList" orgList={state.orgList} onClickOrg={handleOrgClick} />;
    }

    return <div />;
  };

  return (
    <>
      <div key="AppBanner" style={{ gridArea: 'banner' }}>
        <Banner handleBack={handleBack} showBack={!!getBackState(state)} />
      </div>
      {currentScreen()}
      <LoadingSync loading={loading.isLoading} message={loading.message} />
      <PasswordModal
        key="PasswordModal"
        modalIsOpen={!!selectedOrg}
        onSubmitCallback={handlePasswordModalAuth}
        setIsOpen={setSelectedOrg}
      />
      <TwoFactorModal
        key="TwoFactorModal"
        modalIsOpen={!!selectedOrg2FA}
        onSubmitCallback={handle2FAModalVerify}
        setIsOpen={setSelectedOrg2FA}
      />
      <B2BPassphraseModal
        key="B2BPassphraseModal"
        modalIsOpen={askPassphrase}
        onSubmitCallback={handleB2BPassphraseModalVerify}
        setIsOpen={b2bPassphraseClose}
      />
    </>
  );
};

const HomepageBase = (props: any): JSX.Element => {
  const b2b = props.b2b !== undefined;
  return <Base Content={Homepage} b2b={b2b} />;
};

export default HomepageBase;
