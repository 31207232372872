import { useState, useEffect } from 'react';

interface WindowSizeI {
  width: number | undefined;
  height: number | undefined;
}
// Hook
function useWindowSize(): any {
  const isClient = typeof window === 'object';

  function getSize(): WindowSizeI {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect((): (() => void) | void => {
    if (!isClient) {
      return;
    }

    function handleResize(): void {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
