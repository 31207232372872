/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useHistory } from 'react-router';
import { useCaptcha } from '@developers/use-captcha';
import Banner from '../views/Banner';
import BasePage from './Base';
import Base from './basePage';
import Form from '../tauria-react-components/molecules/Form';
import useWindowSize from '../tauria-react-components/hooks/useWindowSize';
import { Link as LinkButton } from '../tauria-react-components/particles/Button';
import authApi from '../models/authApi';
import validate, { Result } from '../utilities/validate';
import { Headings, FormTopSpacer } from '../styles';
import { LoadingSync } from '../views/Loading';

type pageIds = 'enter email' | 'success';

const EmailForm: React.FC<{ handleSendPasswordReset: any }> = ({ handleSendPasswordReset }) => {
  const history = useHistory();
  const [errors, setErrors] = React.useState({});
  const windowSize = useWindowSize();

  const verifyEmail = async (formData: any): Promise<void> => {
    const email = formData['EMAIL ADDRESS'];
    if (validate.checkEmail(email) === Result.Success) {
      const maybeErrors = await handleSendPasswordReset(formData);
      if (maybeErrors) {
        setErrors(maybeErrors);
      }
    } else {
      setErrors({ 'EMAIL ADDRESS': Result.InvalidEmail });
    }
  };

  const handleChange = React.useCallback((): void => {
    // Reset Errors on change
    setErrors({});
  }, []);

  return (
    <div>
      <BasePage buttonHref="/register" buttonLabel="CREATE ONE" label="Don't have an account?">
        {windowSize.width >= 900 && (
          <Headings>
            <h1>Password Reset</h1>
            <h2>We&apos;ll help you get back to it</h2>
          </Headings>
        )}
        <FormTopSpacer>
          <Form
            submit={{ area: 'send', name: 'SEND', type: 'button' }}
            rows={[1, 1]}
            colms={[1]}
            areas={[['email'], ['send']]}
            inputs={[{ name: 'EMAIL ADDRESS', area: 'email', type: 'email', autoFocus: true }]}
            errors={errors}
            onSubmit={verifyEmail}
            onChange={handleChange}
            headingStyle={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#6E7D97',
            }}
          />
          <LinkButton
            value="Back to Sign In"
            onClick={(): void => {
              history.push(`/`);
            }}
          />
        </FormTopSpacer>
      </BasePage>
    </div>
  );
};

const EmailSent: React.FC = () => {
  return (
    <div>
      <BasePage buttonHref="/register" buttonLabel="CREATE ONE" label="Don't have an account?">
        <h1>Success</h1>
        <h2>We&apos;ve sent you an email so you can get back to work.</h2>
        <FormTopSpacer />
      </BasePage>
    </div>
  );
};

const PasswordResetPage = (): JSX.Element => {
  const [currentPage, setCurrentPage] = React.useState<pageIds>('enter email');
  const [loading, setLoading] = React.useState<boolean>(false);
  const recaptcha = useCaptcha();
  const handleSendPasswordReset = async (formData: any): Promise<any> => {
    const email = formData['EMAIL ADDRESS'];
    const token = await recaptcha.execute('forgot_password');
    setLoading(true);
    const result = await authApi.forgotPasword(email, token);
    setLoading(false);
    if (result !== undefined && result === true) {
      setCurrentPage('success');
    } else {
      return { 'EMAIL ADDRESS': Result.GeneralError };
    }
    return {};
  };

  React.useEffect(() => {
    document.title = 'Forgot Password - Tauria';
  }, []);

  return (
    <>
      <div key="AppBanner" style={{ gridArea: 'banner' }}>
        <Banner
          handleBack={
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            (): void => {}
          }
          showBack={false}
        />
      </div>
      {currentPage === 'enter email' ? (
        <EmailForm handleSendPasswordReset={handleSendPasswordReset} />
      ) : (
        <EmailSent />
      )}
      <LoadingSync loading={loading} message="Sending Password Reset Email..." />
    </>
  );
};

const ForgotPasswordBase: React.FC = () => {
  return <Base Content={PasswordResetPage} />;
};

export default ForgotPasswordBase;
