import React from 'react';
import { FormInputOptions } from '../../molecules/Form';
import { LabeledEPInput, ErrorField } from '../../particles/Input';

interface InputOptions extends FormInputOptions {
  type: 'email' | 'password';
}

export default (input: InputOptions, key: number, errors: any, onChange: any) => (
  <div key={key} style={{ gridArea: input.area }}>
    <LabeledEPInput
      key={input.name}
      id={input.area}
      style={{ gridArea: input.area }}
      label={input.name}
      type={input.type}
      autoFocus={input.autoFocus}
      headingStyle={input.headingStyle}
      error={!!errors[input.name] || !!errors.ALL}
      onChange={onChange}
      width="100%"
    />
    {errors[input.name] ? <ErrorField>{errors[input.name]}</ErrorField> : ''}
  </div>
);
