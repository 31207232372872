export enum Result {
  Success = '',
  EmptyField = 'Please complete the entire form.',
  RequiredField = 'Please complete the field.',
  ShortOrgName = 'The business name must be at least three characters long',
  OrgExists = 'That name is taken, please use a different name',
  NumberStartOrgName = 'The business name must not start with a number',
  InvalidOrgName = 'The business name is invalid',
  InvalidEmail = 'Email is invalid.',
  InvalidPasswordLength = 'Password must be a minimum of 5 characters.',
  InvalidPasswordCapital = 'Password must contain an uppercase letter.',
  InvalidPasswordNumber = 'Password must contain a number.',
  PasswordMatch = 'Passwords must match.',
  GeneralError = 'Something happened! Please try again later.',
  UserDoesntExist = 'Invalid email/password combination.',
  CaptchaError = 'There was an error validating the Captcha',
  LongRunningError = 'Still processing, please wait.',
  InvalidToken = 'Token is invalid',
  IncorrectPassphrase = 'Incorrect Passphrase',
  ResetPasswordFail = 'An unexpected error has occurred. Please contact support at support@tauria.com',
}

// eslint-disable-next-line no-useless-escape
const SUPPORTED_EMAIL_PATTERN = /^[A-Za-z0-9\._\-\+]+@[A-Za-z0-9_\-]+\.[A-Za-z0-9\.]+$/;

export default {
  checkEmpty: (values: string[]): Result => {
    let empty = Result.Success;
    values.forEach((v) => {
      if (v === '') {
        empty = Result.EmptyField;
      }
    });
    return empty;
  },
  checkOrganizationLength: (value: string): Result =>
    value.length > 2 && value.length <= 30 ? Result.Success : Result.ShortOrgName,
  checkOrganizationNumberStart: (value: string): Result =>
    !/^[1-9]/.test(value) ? Result.Success : Result.NumberStartOrgName,
  checkOrganization: (value: string): Result => {
    const re = /^[a-zA-Z][0-9a-zA-Z]{2,}$/;
    if (!re.test(value)) {
      return Result.InvalidOrgName;
    }
    return Result.Success;
  },
  checkEmail: (value: string): Result =>
    SUPPORTED_EMAIL_PATTERN.test(String(value).trim().toLowerCase())
      ? Result.Success
      : Result.InvalidEmail,
  checkPasswordLength: (value: string): Result =>
    value.length >= 5 && value.length <= 200 ? Result.Success : Result.InvalidPasswordLength,
  checkPasswordCapital: (value: string): Result =>
    /[A-Z]/.test(value) ? Result.Success : Result.InvalidPasswordCapital,
  checkPasswordNumber: (value: string): Result =>
    /[0-9]/.test(value) ? Result.Success : Result.InvalidPasswordNumber,
  checkPasswordsMatch: (a: string, b: string): Result =>
    a === b ? Result.Success : Result.PasswordMatch,

  checkPasswordsSignin(value: string): boolean {
    return (
      this.checkPasswordCapital(value) === Result.Success &&
      this.checkPasswordLength(value) === Result.Success &&
      this.checkPasswordNumber(value) === Result.Success
    );
  },
  checkTwoFactorCode: (value: string): boolean => value.replace(/\s/g, '').length === 6,
};
