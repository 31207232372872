import React, { useState } from 'react';
import Form from '../tauria-react-components/molecules/Form';
import Base from '../pages/Base';
import Validate, { Result } from '../utilities/validate';
import { Headings } from '../styles';
import { sendPageView } from '../utilities/hubspot';

const Workspace: React.FC<{
  storeWorkspace: (data: Record<string, string>) => Promise<string | undefined>;
}> = ({ storeWorkspace }) => {
  const [url, setUrl] = useState('');
  const [maybeErrors, setErrors] = useState<Record<string, string>>({});

  const handleChange = (values: Record<string, string>): void => {
    const newUrl = values['BUSINESS NAME']?.toLowerCase() || '';

    if (newUrl !== url) {
      setUrl(newUrl);
    }

    setErrors({});
  };

  React.useEffect(() => {
    document.title = 'Set Up Workspace';
    sendPageView(`${window.location.pathname}#set-up-workspace`);
  }, []);

  const handleSubmit = async (values: Record<string, string>): Promise<void> => {
    const workspaceValues = {
      'BUSINESS NAME': values['BUSINESS NAME'],
      'TAURIA ADDRESS': url,
    };

    if (Validate.checkEmpty([workspaceValues['BUSINESS NAME']])) {
      setErrors({ 'BUSINESS NAME': Result.RequiredField });
      return;
    }

    if (Validate.checkOrganization(workspaceValues['BUSINESS NAME']) === Result.InvalidOrgName) {
      setErrors({ 'BUSINESS NAME': Result.InvalidOrgName });
      return;
    }

    const error = await storeWorkspace(workspaceValues);

    if (error) {
      setErrors({ ALL: error });
    }
  };

  return (
    <Base>
      <Headings>
        <h1>Set Up Workspace</h1>
        <h2>Unleash your team&apos;s productivity</h2>
      </Headings>
      <p>
        Your business name should consist of 3 to 30 letters and/or numbers and must start with a
        letter. No other spaces or special characters are allowed.
      </p>
      <Form
        inputs={[{ name: 'BUSINESS NAME', area: 'name', type: 'text', limit: 30, autoFocus: true }]}
        errors={maybeErrors}
        submit={{ name: 'NEXT', area: 'submit', type: 'button' }}
        rows={[1]}
        colms={[1]}
        areas={[['name'], ['prefix'], ['submit']]}
        onSubmit={handleSubmit}
        onChange={handleChange}
      />
    </Base>
  );
};

export default Workspace;
