/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import ConfigManager from '../config';

import Wallpaper from '../img/SignUp-BG.svg';
import Logo from '../img/zebu_logo.svg';
import ChatIcon from '../img/chats__icon.svg';
import CallsIcon from '../img/calls__icon.svg';
import CalendarIcon from '../img/calendar__icon.svg';
import FilesIcon from '../img/files__icon.svg';
import Back from '../img/Back.svg';
import CheckMark from '../img/check_icon.svg';

import { IconButton } from '../tauria-react-components/particles/Button';

const { config } = new ConfigManager(window.origin);

const S = {
  banner: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-image: url(${Wallpaper});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    @media only screen and (max-width: 400px) {
      padding: 0px 10px;
      width: calc(100% - 20px);
    }
  `,
  container: styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 0.5rem;

    h1 {
      color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: calc(59 / 48);
      margin: 2rem 0;
      text-align: center;

      @media only screen and (min-width: 900px) {
        text-align: left;
        font-size: 1.75rem;
        margin: 3.75rem 0;
      }

      @media only screen and (min-width: 1000px) {
        font-size: 2rem;
      }

      @media only screen and (min-width: 1200px) {
        font-size: 3rem;
      }
    }
  `,
  logo: styled.img`
    width: 11.25rem;
    margin: 10px auto;

    @media only screen and (min-width: 900px) {
      width: 12.5rem;
      margin: 0px;
    }
  `,
  moduleRow: styled.div`
    display: flex;
    width: 400px;
    margin-bottom: 20px;
    @media only screen and (max-width: 900px) {
      margin: 0px auto 20px auto;
    }
    @media only screen and (max-width: 500px) {
      width: auto;
    }
  `,
  sbs: styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  `,
  keyline: styled.div`
    margin-right: 43px;
    border-left: 2px solid #0b2055;
    @media only screen and (max-width: 1000px) {
      margin-right: 30px;
    }
    @media only screen and (max-width: 500px) {
      margin-right: 10px;
      max-width: 100%;
      width: auto;
    }
  `,
  h2: styled.h2`
    margin-left: 20px;
    color: #1577fe;
    width: 111px;
    margin-right: 43px;
    @media only screen and (max-width: 1000px) {
      margin-right: 20px;
    }
    @media only screen and (max-width: 500px) {
      margin-right: 10px;
      font-size: 16px;
      width: 80px;
    }
  `,
  counter: styled.div<{ state: string }>`
    color: #4d4d4d;
    background-color: transparent;
    border: solid 2px #4d4d4d;
    padding: 10px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 20px;
    margin-right: 30px;

    ${(p): string =>
      p.state === 'active'
        ? `
        background-color: #1577FE;
        color: #ffffff;
        border-color: #1577FE;
      `
        : ''}

    ${(p): string =>
      p.state === 'done'
        ? `
        background-color: #4D4D4D;
        color: black;
        border-color: #4D4D4D;
      `
        : ''}
  `,
  stepText: styled.div<{ solid: boolean }>`
    display: flex;
    font-size: 20px;
    align-items: center;
    color: #4d4d4d;

    ${(p): string =>
      p.solid
        ? `
        color: #1577FE;
      `
        : ''}
  `,
  stepContainer: styled.div`
    display: flex;
    margin-bottom: 20px;
    width: 50%;
    @media only screen and (max-width: 1200px) {
      width: 80%;
    }
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    justify-content: space-between;
  `,
  watermark: styled.p`
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.3);
  `,
  watermarkDONOTUSEENV: styled.p`
    position: absolute;
    bottom: calc(100% - 94%);
    font-size: 2vw;
    font-weight: bold;
    color: red;
    z-index: 1;
  `,
};

const Modules: React.FC = (): any => {
  return [
    <S.moduleRow key="Chats Calls Row">
      <S.sbs>
        <S.keyline />
        <img alt="chat icon" src={ChatIcon} />
        <S.h2>Chats</S.h2>
      </S.sbs>
      <S.sbs>
        <S.keyline />
        <img alt="call icon" src={CallsIcon} />
        <S.h2>Calls</S.h2>
      </S.sbs>
    </S.moduleRow>,
    <S.moduleRow key="Calendar Storage row">
      <S.sbs style={{ marginBottom: '0px' }}>
        <S.keyline style={{ marginBottom: '0px' }} />
        <img alt="calendar icon" src={CalendarIcon} />
        <S.h2>Calendar</S.h2>
      </S.sbs>
      <S.sbs style={{ marginBottom: '0px' }}>
        <S.keyline />
        <img alt="files icon" src={FilesIcon} />
        <S.h2>Storage</S.h2>
      </S.sbs>
    </S.moduleRow>,
  ];
};

const RenderStep: React.FC<{ curStep: number; targetStep: number; label: string }> = ({
  curStep,
  targetStep,
  label,
}) => (
  <S.stepContainer>
    <S.stepText solid={curStep === targetStep}>
      <S.counter state={curStep === targetStep ? 'active' : curStep > targetStep ? 'done' : ''}>
        {targetStep}
      </S.counter>
      {label}
    </S.stepText>
    {curStep > targetStep && (
      <img src={CheckMark} style={{ paddingLeft: '30px' }} alt="Checkmark" />
    )}
  </S.stepContainer>
);

const RegisterSteps: React.FC<{ step: number }> = ({ step }) => {
  return (
    <>
      <RenderStep curStep={step} targetStep={1} label="Set Up Workspace" />
      <RenderStep curStep={step} targetStep={2} label="Personal Details" />
      <RenderStep curStep={step} targetStep={3} label="Set Password" />
    </>
  );
};

const UserRegisterSteps: React.FC<{ step: number; extraStep: boolean | undefined }> = ({
  step,
  extraStep,
}) => {
  return (
    <>
      <RenderStep curStep={step} targetStep={1} label="Personal Details" />
      {extraStep && <RenderStep curStep={step} targetStep={2} label="User Information" />}
      <RenderStep curStep={step} targetStep={extraStep ? 3 : 2} label="Set Password" />
    </>
  );
};

const PasswordResetSteps: React.FC<{ step: number }> = ({ step }) => {
  return (
    <>
      <RenderStep curStep={step} targetStep={1} label="Reset Password" />
    </>
  );
};

type BannerProps = {
  handleBack: () => void;
  showBack: boolean;
  registerStep?: number;
  userStep?: number;
  userExtraStep?: boolean;
  resetStep?: number;
};

const Banner = ({
  handleBack,
  showBack,
  registerStep,
  userStep,
  userExtraStep,
  resetStep,
}: BannerProps): JSX.Element => {
  const bannerContent = registerStep ? (
    <RegisterSteps step={registerStep} />
  ) : userStep ? (
    <UserRegisterSteps step={userStep} extraStep={userExtraStep} />
  ) : resetStep ? (
    <PasswordResetSteps step={resetStep} />
  ) : (
    <Modules />
  );

  return (
    <S.banner>
      {showBack && (
        <IconButton
          src={Back}
          width="14px"
          height="23px"
          onClick={handleBack}
          onKeyPress={handleBack}
          tabIndex={-1}
          style={{ position: 'absolute', top: '20px', left: '20px' }}
        />
      )}
      <S.container>
        <S.logo src={Logo} alt="Tauria" />
        <h1>
          Secure collaboration
          <wbr /> platform for businesses
        </h1>
        {bannerContent}
      </S.container>
      {config.domain === 'dev.zebu.io' && (
        <S.watermarkDONOTUSEENV>DO NOT USE - DEV/FAT ENV</S.watermarkDONOTUSEENV>
      )}
      <S.watermark>{config.domain === 'zebu.io' ? '' : config.domain}</S.watermark>
    </S.banner>
  );
};

export default Banner;
