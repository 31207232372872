import React from 'react';
import styled, { CSSObject } from 'styled-components';
import ColorManager from '../extras/Colors';

import suLink from '../extras/img/su_link.svg';
import sgPhone from '../extras/img/sg_phone.svg';
import swCheck from '../extras/img/sw_check_circled.svg';

import { copyToClipboard } from '../extras/utils';

interface ButtonProps {
  value?: string;
  style?: CSSObject;
  color?: string;
  textColor?: string;
  width?: string;
  disabled?: boolean;
  padding?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
}

interface ImageButtonProps extends ButtonProps {
  src: string;
  leftSide?: boolean;
}

interface CopyButtonProps extends ButtonProps {
  toCopy: string;
}

const baseButton = `
  border-radius: 5px;
  border: none;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;
`;

export const IconButton = styled.button<{ src: string; width: string; height: string }>`
  background: transparent;
  background-image: url(${(p): string => p.src});
  background-size: ${(p): string => p.width} auto;
  background-position: center;
  background-repeat: no-repeat;
  padding: calc(${(p): string => p.height} + 5px) calc(${(p): string => p.width} + 5px);
  border: 0px;
  cursor: pointer;
  outline: none;
  transition: box-shadow 0.2s;
`;

const S = {
  button: styled.button<{ width?: string; color?: string; textColor?: string; padding?: string }>`
    background-color: ${(p): string => (p.color ? p.color : new ColorManager().default)};
    color: ${(p): string => (p.textColor ? p.textColor : '#ffffff')};
    width: ${(p): string => (p.width ? p.width : 'calc(100% - 24px)')};
    padding: ${(p): string => (p.padding ? p.padding : '12px 40px')};
    ${baseButton}
  `,
  imageButton: styled.button<ImageButtonProps>`
    ${baseButton}
    background-color: ${(p): string => (p.color ? p.color : new ColorManager().default)};
    color: ${(p): string => (p.textColor ? p.textColor : '#ffffff')};
    width: ${(p): string => (p.width ? p.width : 'calc(100%)')};
    padding: ${(p): string =>
      // eslint-disable-next-line no-nested-ternary
      p.src === '' ? '12px' : p.leftSide ? '12px 40px 12px 40px' : '12px 40px 12px 40px'};

    background-image: url(${(p): string => p.src});
    background-position: ${(p): string => (p.leftSide ? '10% center' : '90% center')};
    background-repeat: no-repeat;
    background-size: 16px auto;
  `,
  linkButton: styled.button`
    background-color: transparent;
    outline: none;
    width: 100%;
    color: #5b6074;
    border: 0px;
    padding: 20px 0px;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  `,
};

type TauriaButtonProps = {
  color?: 'primary' | 'secondary' | React.CSSProperties['backgroundColor'];
  variant?: 'contained' | 'outlined' | 'ghost';
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  iconPosition?: 'right' | 'left';
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
};

const ButtonInnerWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getBackgroundColor = ({ color }: TauriaButtonProps): string => {
  switch (color) {
    case 'primary':
      return '#1577fe';

    case 'secondary':
      return '#0b1328';

    default:
      return String(color);
  }
};

const getTextColor = ({ color }: TauriaButtonProps): string => {
  switch (color) {
    case 'primary':
    case 'secondary':
    default:
      return '#ffffff';
  }
};

const StyledButton = styled.button<{
  color: TauriaButtonProps['color'];
  variant: TauriaButtonProps['variant'];
}>`
  background-color: ${(p) => getBackgroundColor(p)};
  color: ${(p) => getTextColor(p)};
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  line-height: calc(17px / 14px);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 200ms ease;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  ${(p) => p.disabled && 'pointer-events: none;'};
  border: 2px solid ${(p) => getBackgroundColor(p)};

  // TODO: Implement hover states when there are designs for it

  :focus,
  :active {
    outline: 2px solid ${(p) => getBackgroundColor(p)};
    border-color: #ffffff;
  }
`;

export const TauriaButton = ({
  color = 'primary',
  variant = 'contained',
  style = {},
  icon = null,
  iconPosition = 'right',
  onClick = () => {},
  disabled = false,
  children = null,
}: TauriaButtonProps): JSX.Element => {
  return (
    <StyledButton
      style={style}
      color={color}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      <ButtonInnerWrapper>
        {icon && iconPosition === 'left' ? (
          <span style={{ marginRight: '0.75em' }}>{icon}</span>
        ) : null}
        <span>{children}</span>
        {icon && iconPosition === 'right' ? (
          <span style={{ marginLeft: '0.75em' }}>{icon}</span>
        ) : null}
      </ButtonInnerWrapper>
    </StyledButton>
  );
};

export const Button = ({
  value,
  onClick,
  onKeyPress,
  width,
  color,
  textColor,
  padding,
  style,
}: ButtonProps): JSX.Element => {
  return (
    <S.button
      style={style}
      onClick={onClick}
      onKeyPress={onKeyPress}
      width={width}
      color={color}
      textColor={textColor}
      padding={padding}
    >
      {value}
    </S.button>
  );
};

export const ImageButton = ({
  value,
  onClick,
  width,
  src,
  color,
  textColor,
  disabled,
  padding,
}: ImageButtonProps) => {
  return (
    <S.imageButton
      onClick={onClick}
      width={width}
      src={src}
      color={color}
      textColor={textColor}
      disabled={disabled}
      padding={padding}
    >
      {value}
    </S.imageButton>
  );
};

export const URLCopyButton = ({
  value,
  width,
  toCopy,
  color,
  textColor,
}: CopyButtonProps): JSX.Element => {
  const [clicked, setClicked] = React.useState(false);

  const onClick = React.useCallback((): void => {
    copyToClipboard(toCopy);
    setClicked(true);
  }, [toCopy]);

  React.useEffect(() => {
    let unmounted = false;

    // Automatically flip the `clicked` flag back to `false` after 2 seconds
    if (clicked) {
      setTimeout(() => {
        if (!unmounted) {
          setClicked(false);
        }
      }, 2000);
    }

    return (): void => {
      unmounted = true;
    };
  }, [clicked]);

  return (
    <ImageButton
      onClick={onClick}
      value={clicked ? 'COPIED' : value}
      width={width}
      src={clicked ? swCheck : suLink}
      color={clicked ? '#0ED266' : color}
      textColor={clicked ? 'white' : textColor}
      leftSide
    />
  );
};

export const CallButton = ({ value, onClick, width }: ButtonProps): JSX.Element => {
  return (
    <ImageButton
      onClick={onClick}
      width={width}
      src={sgPhone}
      color={new ColorManager().lightGreen}
      textColor={new ColorManager().green}
      value={value}
      leftSide
    />
  );
};

export const Link = ({ value, onClick, style }: ButtonProps): JSX.Element => {
  return (
    <S.linkButton style={style} onClick={onClick}>
      {value}
    </S.linkButton>
  );
};

export default Button;
