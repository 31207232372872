import React from 'react';
import styled from 'styled-components';

import loader from '../extras/img/loader.svg';

const LoadingStyle = {
  LoadingSvg: styled.img`
    animation: rotate 1s ease-in-out infinite;
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `,
  LoadingDivContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
};

const Loader: any = () => (
  <LoadingStyle.LoadingDivContainer>
    <LoadingStyle.LoadingSvg src={loader} />
  </LoadingStyle.LoadingDivContainer>
);
export default Loader;
