import React from 'react';
import styled from 'styled-components';
import useWindowSize from '../tauria-react-components/hooks/useWindowSize';
import lock from '../tauria-react-components/extras/img/sb_lock.svg';
import { Headings } from '../styles';

const Container = styled.div`
  background-color: #f7f8f9;
  width: 100%;
  padding: 20px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  @media only screen and (max-width: 1100px) {
    padding: 20px;
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 0;
  }
`;

const List = styled.div`
  background-color: transparent;
  width: calc(100% - 160px);
  margin-top: 50px;
  @media only screen and (max-width: 1100px) {
    width: calc(100% - 40px);
  }
  @media only screen and (max-width: 600px) {
    width: calc(100% - 20px);
    margin-left: 10px;
    height: auto;
    margin-bottom: 0px;
  }
`;

const Card = styled.div`
  cursor: pointer;
  width: calc(100% - 50px);
  background-color: white;
  border-radius: 5px;
  display: flex;
  color: #282a36;
  justify-content: space-between;
  padding: 18px 25px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  & > h3 {
    margin: 0px;
  }
`;

export interface OrganizationInfo {
  orgGuid: string;
  orgName: string;
}
export interface Organization {
  isAuthenticated: boolean;
  // eslint-disable-next-line camelcase
  twofa_required: 'true' | 'false';
  organization: OrganizationInfo;
}

export interface OrgListInterface {
  orgList: Organization[] | undefined;
}

const OrgCard: React.FC<{
  org: Organization;
  isAuthenticated: boolean;
  onClick(org: Organization): void;
}> = ({ org, isAuthenticated, onClick }) => {
  return (
    <Card id={org.organization.orgName} onClick={(): void => onClick({ ...org, isAuthenticated })}>
      <h3>{org.organization.orgName}</h3>
      {!isAuthenticated ? (
        <img src={lock} alt="lock" style={{ width: '18px', height: 'auto' }} />
      ) : null}
    </Card>
  );
};

type OrgListProps = { orgList: OrgListInterface; onClickOrg(org: Organization): void };

const OrgList = ({ orgList, onClickOrg }: OrgListProps): JSX.Element => {
  const windowSize = useWindowSize();

  const [authenticatedOrgs, intrudingOrgs] = React.useMemo(() => {
    const authenticated: Organization[] = [];
    const intruding: Organization[] = [];
    const orgs = orgList?.orgList || [];

    orgs.forEach((org) => {
      if (org.isAuthenticated) {
        authenticated.push(org);
      } else {
        intruding.push(org);
      }
    });

    return [authenticated, intruding];
  }, [orgList]);

  return (
    <Container>
      {windowSize.width >= 900 && (
        <Headings>
          <h1>Welcome Back</h1>
          <h2>Select the workspace you want to use</h2>
        </Headings>
      )}
      <List>
        {authenticatedOrgs.map((mOrg: Organization) => (
          <OrgCard
            key={mOrg.organization.orgGuid}
            org={mOrg}
            onClick={onClickOrg}
            isAuthenticated
          />
        ))}
        {intrudingOrgs.map((nOrg) => (
          <OrgCard
            key={nOrg.organization.orgGuid}
            org={nOrg}
            onClick={onClickOrg}
            isAuthenticated={false}
          />
        ))}
      </List>
    </Container>
  );
};

export default OrgList;
