import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import Form from '../tauria-react-components/molecules/Form';
import close from '../tauria-react-components/extras/img/sg_circle_close.svg';
import { IconButton, Link as LinkButton } from '../tauria-react-components/particles/Button';
import { LoadingSync } from './Loading';
import { Result } from '../utilities/validate';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

const S = {
  passwordContainer: styled.div`
    padding: 60px 100px;
    @media only screen and (max-width: 900px) {
      width: calc(100vw - 240px);
      padding: 60px;
    }
    @media only screen and (max-width: 500px) {
      width: calc(100vw - 80px);
      padding: 20px;
    }
  `,
  modalBodyText: styled.div`
    text-align: center;
  `,
  header: styled.div`
    font-size: 24px;
    color: #191a21;
    font-weight: 600;
    margin-bottom: 20px;
  `,
  description: styled.div`
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  `,
};

if (typeof document !== 'undefined' && document.getElementById('#root')) {
  Modal.setAppElement('#root');
}

interface BodyForm {
  errors: Record<string, string>;
  handleSubmit: (arg0: { CODE: string } & { PASSWORD: string }) => Promise<void>;
}

type editFunction = ({ CODE }: { CODE: string }) => Promise<{ error: string } | undefined>;
type passwordFunction = ({
  PASSWORD,
}: {
  PASSWORD: string;
}) => Promise<{ error: string } | undefined>;

interface ModalInput {
  modalIsOpen: boolean;
  onSubmitCallback: editFunction | passwordFunction;
  setIsOpen: Function;
}
const PasswordBodyForm: React.FC<BodyForm> = ({ errors, handleSubmit }) => {
  const history = useHistory();
  return (
    <>
      <Form
        submit={{ area: 'login', name: 'SUBMIT', type: 'button' }}
        rows={[1]}
        colms={[1]}
        areas={[['password'], ['login']]}
        inputs={[
          {
            name: 'PASSWORD',
            area: 'password',
            type: 'password',
            autoFocus: true,
          },
        ]}
        errors={errors}
        onSubmit={handleSubmit}
      />
      <LinkButton
        value="Forgot your password?"
        onClick={(): void => {
          history.push(`/forgot_your_password`);
        }}
      />
    </>
  );
};

const TwoFactorBodyForm: React.FC<BodyForm> = ({ errors, handleSubmit }) => {
  return (
    <Form
      submit={{ area: 'login', name: 'SUBMIT', type: 'button' }}
      rows={[1]}
      colms={[1]}
      areas={[['code'], ['login']]}
      inputs={[
        {
          name: 'CODE',
          area: 'code',
          type: 'text',
          autoFocus: true,
        },
      ]}
      errors={errors}
      onSubmit={handleSubmit}
    />
  );
};

const B2BPassphraseBodyForm: React.FC<BodyForm> = ({ errors, handleSubmit }) => {
  return (
    <Form
      submit={{ area: 'login', name: 'SUBMIT', type: 'button' }}
      rows={[1]}
      colms={[1]}
      areas={[['code'], ['login']]}
      inputs={[
        {
          name: 'CODE',
          area: 'code',
          type: 'text',
          autoFocus: true,
        },
      ]}
      errors={errors}
      onSubmit={handleSubmit}
    />
  );
};

const CustomAuthModel: React.FC<
  ModalInput & { header: string; description: string; bodyForm: React.FC<BodyForm> }
> = ({ modalIsOpen, setIsOpen, header, description, onSubmitCallback, bodyForm: BodyForm }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const closeModal = (): void => {
    setErrors({});
    setIsOpen(false);
  };
  const handleSubmit = async (key: { CODE: string } & { PASSWORD: string }): Promise<void> => {
    // setLoading(true);
    try {
      const err = await onSubmitCallback(key);
      let field = 'PASSWORD';
      if ('CODE' in key) {
        field = 'CODE';
      }
      if (err && 'error' in err) {
        setErrors({ [field]: err.error });
        setLoading(false);
      }
      if (err === undefined) {
        setErrors({});
      }
    } catch (error) {
      setErrors({ ALL: Result.GeneralError });
      setLoading(false);
    }
  };
  const passwordForm = (
    <S.passwordContainer>
      <IconButton
        src={close}
        width="18px"
        height="18px"
        onClick={closeModal}
        onKeyPress={closeModal}
        tabIndex={0}
        style={{ position: 'absolute', top: '2px', right: 0 }}
      />
      <S.modalBodyText>
        <S.header>{header}</S.header>
        <S.description>{description}</S.description>
      </S.modalBodyText>
      <BodyForm errors={errors} handleSubmit={handleSubmit} />
      <LinkButton value="Cancel" onClick={closeModal} />
    </S.passwordContainer>
  );
  return (
    <>
      <Modal
        isOpen={!loading && modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Password Confirm Modal"
      >
        {passwordForm}
      </Modal>

      <LoadingSync loading={loading} message="Signing in..." />
    </>
  );
};

export const PasswordModal: React.FC<ModalInput> = ({
  modalIsOpen,
  setIsOpen,
  onSubmitCallback,
}) => (
  <CustomAuthModel
    header="Password Verification"
    description="Fill your password to proceed. We do this in order to protect your account."
    modalIsOpen={modalIsOpen}
    setIsOpen={setIsOpen}
    onSubmitCallback={onSubmitCallback}
    bodyForm={PasswordBodyForm}
  />
);

export const TwoFactorModal: React.FC<ModalInput> = ({
  modalIsOpen,
  setIsOpen,
  onSubmitCallback,
}) => (
  <CustomAuthModel
    header="Two Factor Verification"
    description="Fill your two factor authentication code to proceed."
    modalIsOpen={modalIsOpen}
    setIsOpen={setIsOpen}
    onSubmitCallback={onSubmitCallback}
    bodyForm={TwoFactorBodyForm}
  />
);

export const B2BPassphraseModal: React.FC<ModalInput> = ({
  modalIsOpen,
  setIsOpen,
  onSubmitCallback,
}) => (
  <CustomAuthModel
    header="B2B Passphrase Verification"
    description="Fill your passphrase provided by the inviter to proceed."
    modalIsOpen={modalIsOpen}
    setIsOpen={setIsOpen}
    onSubmitCallback={onSubmitCallback}
    bodyForm={B2BPassphraseBodyForm}
  />
);
