/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCaptcha } from '@developers/use-captcha';
import Encryption from '../utilities/encryption';
import Base from './basePage';

import Banner from '../views/Banner';

import Workspace from '../RegisterPages/Workspace';

import Details from '../RegisterPages/Details';
import Password from '../RegisterPages/Password';
import Success from '../RegisterPages/Success';

import { Result } from '../utilities/validate';

import registerApi from '../models/registerApi';
import ResendEmail, { EMAIL_KEY } from '../RegisterPages/Resend';

import { LoadingSync } from '../views/Loading';
import { sendEvent, setIdentity } from '../utilities/hubspot';

interface RegisterState {
  step: number;
  businessName: string;
  businessURL: string;
  prefix: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  newsletter: string;
  updateToken: string;
}

const Register: React.FC = (): any => {
  const [state, setState] = React.useState({ step: 1 } as RegisterState);
  const [loading, setLoading] = React.useState<{ isLoading: boolean; message: string }>({
    isLoading: false,
    message: '',
  });

  const recaptcha = useCaptcha();
  const history = useHistory();

  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const promotion = query.get('promotion');
    if (promotion) {
      localStorage.setItem('@tauria-campaign-promotion', promotion);
    }
  }, []);

  React.useEffect(() => {
    document.title = 'Register - Tauria';
  }, []);

  const buildOrganization = async (password: string): Promise<Result | string> => {
    let captcha = '';
    // handle captcha on the server side
    try {
      captcha = await recaptcha.execute('register_org');
    } catch (e) {
      if (e === null) console.error('No token retrieved');
      // return Result.CaptchaError;
    }
    localStorage.setItem('@tauria-campaign-prefix', state.businessURL);
    const createResp = registerApi.createOrg(
      state.email,
      state.firstName,
      state.lastName,
      password || state.password,
      state.businessURL,
      state.businessName,
      '',
      false,
      captcha,
    );
    // wait for org to be ready
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(createResp);
      }, 2000);
    });
  };

  const getOrgToken = async (
    orgName: string,
    userName: string,
    userEmail: string,
  ): Promise<Result | string> => {
    return registerApi.getOrgRegToken(orgName, userName, userEmail);
  };

  const canGoBack = (): boolean => state.step !== 1 && state.step !== 5;

  const handleBack = (): void => {
    if (canGoBack()) {
      const newState = { ...state };
      newState.step -= 1;
      setState(newState);
    }
  };

  const storeWorkspace = async (
    values: Record<'BUSINESS NAME' | 'TAURIA ADDRESS', string>,
  ): Promise<string | undefined> => {
    let token = '';

    try {
      token = await recaptcha.execute('verify_org_name');
    } catch (e) {
      if (e === null) console.error('No token retrieved');
      // return Result.CaptchaError;
    }

    const result = await registerApi.verifyOrgName(values['TAURIA ADDRESS'], token);

    if (result === Result.Success) {
      setState({
        ...state,
        businessName: values['BUSINESS NAME'],
        businessURL: values['TAURIA ADDRESS'],
        step: 2,
      });
      sendEvent('Step Completed: Set Up Workspace');
      return undefined;
    }
    return result;
  };

  const storeDetails = (values: any): void => {
    const newState = { ...state };
    newState.email = values['WORK EMAIL'];
    newState.firstName = values['FIRST NAME'];
    newState.lastName = values['LAST NAME'];
    newState.step = 3;
    setState(newState);

    setIdentity({
      email: newState.email,
      firstname: newState.firstName || '',
      lastname: newState.lastName || '',
    });
    sendEvent('Step Completed: Personal Details');
  };

  const storePassword = async (values: any): Promise<Result | undefined> => {
    const newState = { ...state };
    newState.password = Encryption.encryptPassword(values.PASSWORD);
    setLoading({ isLoading: true, message: 'Generating Confirmation Token...' });
    const result = await buildOrganization(newState.password);

    if (result === Result.GeneralError) {
      return Result.GeneralError;
    }

    setTimeout(async () => {
      const token = await getOrgToken(
        state.businessName,
        `${newState.firstName} ${newState.lastName}`,
        newState.email,
      );
      console.log('store password: got token', token);
      setLoading({ isLoading: false, message: '' });

      setState({ ...state, step: 4, updateToken: result });
      history.push({
        pathname: `/${token}/validate`,
        state: { passHash: values.PASSWORD, email: newState.email },
      });

      sendEvent('Step Completed: Set Password');
    }, 2000);

    return undefined;
  };

  const onWrongEmail = (): void => setState({ ...state, step: 5 });

  const onSendNewEmail = async (
    {
      [EMAIL_KEY]: emailAddress,
    }: {
      [EMAIL_KEY]: string;
    },
    showLoadingScreen: () => void,
    hideLoadingScreen: () => void,
  ): Promise<void> => {
    showLoadingScreen();
    const promises: [Promise<Result>, Promise<void>] = [
      registerApi.ChangeAndResendEmail(state.businessName, emailAddress, state.updateToken),
      new Promise((resolve) => setTimeout(() => resolve(), 6000)),
    ];
    await Promise.all(promises);
    hideLoadingScreen();
    setState({ ...state, step: 4, email: emailAddress });
  };

  const onResendEmail = async (
    showLoadingScreen: () => void,
    hideLoadingScreen: () => void,
  ): Promise<void> => {
    showLoadingScreen();
    const promises: [Promise<Result>, Promise<void>] = [
      registerApi.ResendEmail(state.businessName, state.updateToken),
      new Promise((resolve) => setTimeout(() => resolve(), 6000)),
    ];
    await Promise.all(promises);
    hideLoadingScreen();
    setState({ ...state, step: 4 });
  };

  const getCurrentPage = (): React.ReactNode => {
    if (state.step === 1) {
      return <Workspace storeWorkspace={storeWorkspace} />;
    }
    if (state.step === 2) {
      return <Details storeDetails={storeDetails} />;
    }
    if (state.step === 3) {
      return <Password storePassword={storePassword} />;
    }
    if (state.step === 4) {
      return (
        <Success email={state.email} onResendEmail={onResendEmail} onWrongEmail={onWrongEmail} />
      );
    }
    if (state.step === 5) {
      return <ResendEmail onSend={onSendNewEmail} />;
    }

    return <div />;
  };

  return (
    <>
      <div style={{ gridArea: 'banner' }}>
        <Banner handleBack={handleBack} registerStep={state.step} showBack={canGoBack()} />
      </div>
      <LoadingSync loading={loading.isLoading} message={loading.message} />
      <div style={{ gridArea: 'form' }}>{getCurrentPage()}</div>
    </>
  );
};

const RegisterPage: React.FC = () => {
  return <Base Content={Register} />;
};

export default RegisterPage;
