import React from 'react';
import Base from '../pages/Base';
import { Headings } from '../styles';

const Success = (): JSX.Element => {
  return (
    <Base showFooter>
      <Headings>
        <h1>Success!</h1>
      </Headings>
      <p>You will be redirected in a moment. Please wait...</p>
    </Base>
  );
};

export default Success;
