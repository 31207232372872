import React from 'react';
import styled from 'styled-components';

export interface LayoutProps {
  rows: number[];
  colms: number[];
  areas: string[][];
  gap?: string;
  fullscreen?: boolean;
}

export const LayoutS = styled.div<LayoutProps>`
  min-height: ${(p): string => (p.fullscreen ? '100vh' : '100%')};
  min-width: ${(p): string => (p.fullscreen ? '100vw' : '100%')};
  display: grid;
  grid-gap: ${(p): string => (p.gap ? p.gap : '0px')};
  grid-template-columns: ${(p): string => p.colms.map((colm) => `${colm}fr`).join(' ')};
  grid-template-rows: ${(p): string => p.rows.map((row) => `${row}fr`).join(' ')};
  grid-template-areas: "${(p): string =>
    p.areas.map((areas) => areas.map((area) => `${area}`).join(' ')).join('"\n"')}";
`;

const Layout: React.FC<LayoutProps> = ({ rows, colms, areas, children, gap }) => {
  return (
    <LayoutS rows={rows} colms={colms} areas={areas} gap={gap} fullscreen>
      {children}
    </LayoutS>
  );
};

export default Layout;
