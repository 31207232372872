import React, { useState } from 'react';
import styled from 'styled-components';
import Form from '../tauria-react-components/molecules/Form';
import Loader from '../tauria-react-components/molecules/Loader';
import Base from '../pages/Base';

import validate, { Result } from '../utilities/validate';
import { Headings } from '../styles';
import { sendPageView } from '../utilities/hubspot';

export const EMAIL_KEY = 'EMAIL ADDRESS';

const LoaderStyles = {
  container: styled.div<{ showLoading: boolean }>`
    ${(p): string => `display: ${p.showLoading ? 'flex' : 'none'};`}
    flexDirection: column;
    position: fixed;
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 50%;
    background: rgba(2, 8, 25, 0.9);
    height: 100%;
    align-items: center;
    top: 0;
    right: 0;
  `,
  center: styled.div`
    margin: auto;
  `,
  message: styled.div`
    margin-top: 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  `,
};

export const ResendingLoader: React.FC<{ showLoading: boolean }> = ({ showLoading }) => (
  <LoaderStyles.container showLoading={showLoading}>
    <LoaderStyles.center>
      <Loader />
      <LoaderStyles.message>Resending e-mail...</LoaderStyles.message>
    </LoaderStyles.center>
  </LoaderStyles.container>
);

type ResendPayload = { [EMAIL_KEY]: string };

const ResendEmail: React.FC<{
  onSend: (
    data: ResendPayload,
    showLoadingScreen: () => void,
    hideLoadingScreen: () => void,
  ) => void;
}> = ({ onSend }) => {
  const [errors, setErrors] = useState({});
  const [showLoading, __setShowLoading] = useState(false);

  React.useEffect(() => {
    document.title = 'Change Your Email';
    sendPageView(`${window.location.pathname}#change-your-email`);
  }, []);

  const handleSubmit = (values: ResendPayload): void => {
    const email = values[EMAIL_KEY].trim();
    if (validate.checkEmail(email) === Result.InvalidEmail) {
      setErrors({ [EMAIL_KEY]: Result.InvalidEmail });
    } else {
      onSend(
        values,
        () => __setShowLoading(true),
        () => __setShowLoading(false),
      );
    }
  };

  const handleChange = React.useCallback((): void => {
    // Reset Errors on change
    setErrors({});
  }, []);

  return (
    <Base showFooter={false}>
      <Headings>
        <h1 style={{ marginBottom: '100px' }}>Change Your Email</h1>
      </Headings>
      <Form
        inputs={[{ name: EMAIL_KEY, area: 'email', type: 'text', autoFocus: true }]}
        errors={errors}
        submit={{ name: 'SEND', area: 'submit', type: 'button' }}
        rows={[1]}
        colms={[1]}
        areas={[['email'], ['submit']]}
        onSubmit={handleSubmit}
        onChange={handleChange}
      />
      <ResendingLoader showLoading={showLoading} />
    </Base>
  );
};

export default ResendEmail;
