/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import Encryption from '../utilities/encryption';
import Base from './basePage';

import Banner from '../views/Banner';
import Password from '../ResetPages/Password';
import Success from '../ResetPages/Success';
import { Result } from '../utilities/validate';
import connectApi, { ResetData } from '../models/connectApi';
import authApi from '../models/authApi';
import ContactSupport from '../ResetPages/ContactSupport';

export interface StateMachine {
  step: number;
  email?: string;
  passwordHash: string;
  confirmPasswordHash: string;
  loginHash: string;
  firstName?: string;
  lastName?: string;
  token: string;
  login?: string;
  prefix: string;
}

const isString = (str: unknown): str is string => {
  return typeof str === 'string';
};

function isCompleteResetData(obj: any): obj is ResetData {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return obj.firstName && isString(obj.lastName) && obj.login && obj.email;
}

function resetPassword(state: StateMachine): Promise<boolean> | boolean {
  if (isCompleteResetData(state)) {
    return connectApi.resetPassword(state);
  }

  return false;
}

const ResetPasswordContent: React.FC<{ token: string; prefix: string }> = ({ token, prefix }) => {
  const history = useHistory();
  const [state, setState] = useState<StateMachine>({
    step: token === 'None' ? 3 : 1,
    token,
    prefix,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    login: undefined,
    passwordHash: '',
    confirmPasswordHash: '',
    loginHash: '',
  });

  React.useEffect(() => {
    document.title = 'Reset Password - Tauria';
  }, []);

  useEffect(() => {
    const getSignupData = async (): Promise<void> => {
      try {
        const signupUser = await authApi.passwordResetInfo(token, prefix);

        if (!signupUser && token !== 'None') {
          history.push('/');
          return;
        }
        // no signup user and token is None, do nothing
        if (!signupUser) return;

        setState({
          ...state,
          email: signupUser.email,
          firstName: signupUser.name,
          lastName: signupUser.last_name,
          login: signupUser.login,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };

    if (state.email === undefined) {
      getSignupData();
    }
  });

  const handleStorePassword = async (
    values: Record<'PASSWORD' | 'CONFIRM PASSWORD', string>,
  ): Promise<Result.Success | Result.ResetPasswordFail> => {
    const newState = { ...state };
    newState.passwordHash = Encryption.encryptPassword(values.PASSWORD);
    newState.confirmPasswordHash = Encryption.encryptPassword(values['CONFIRM PASSWORD']);
    const success = await resetPassword(newState);

    if (success) {
      setState({ ...state, step: state.step + 1 });
      return Result.Success;
    }

    return Result.ResetPasswordFail;
  };

  const getPage = (): JSX.Element => {
    if (state.step === 1) {
      return <Password storePassword={handleStorePassword} givenEmail={state.email} />;
    }
    if (state.step === 2) {
      return <Success />;
    }
    return <ContactSupport />;
  };
  return (
    <>
      <div style={{ gridArea: 'banner' }}>
        <Banner
          resetStep={state.step}
          showBack={state.step === 2}
          handleBack={(): void => {
            setState({ ...state, step: state.step - 1 });
          }}
        />
      </div>
      <div style={{ gridArea: 'form' }}>{getPage()}</div>
    </>
  );
};

const ResetPassword = (): JSX.Element => {
  const { token, prefix } = useParams();
  return <Base Content={ResetPasswordContent} token={token} prefix={prefix} />;
};

export default ResetPassword;
