import forge from 'node-forge';

const encryptPassword = (raw: string, isLocal: boolean): string => {
  const md = forge.md.sha256.create();
  md.update(raw);
  if (isLocal) {
    md.update('zebu.encryption');
  }
  return md.digest().toHex();
};

const Encryption = {
  encryptPasswordLegacy(raw: string): string {
    return encryptPassword(raw, false);
  },

  // Uses New pbkdf rounds
  encryptPassword(raw: string): string {
    return forge.util.bytesToHex(forge.pkcs5.pbkdf2(raw, 'tauria.encryption', 12437, 32));
  },

  // uses legacy sha256 encryption with the extra step
  getLoginHash(raw: string): string {
    return encryptPassword(raw, true);
  },
};

export default Encryption;
