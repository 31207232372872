import React from 'react';
import { Switch, Route, useHistory } from 'react-router';
import Homepage from './pages/home';
import Register from './pages/register';
import ForgotPassword from './pages/forgotPassword';
import BuildingOrg from './RegisterPages/Building';
import VerifyEmail from './RegisterPages/VerifyEmail';
import NewUsers from './pages/newUser';
import PasswordReset from './pages/resetPassword';
import ConfigManager from './config';
import { BASE_PATH, HUBSPOT_ID } from './constants';
import { sendPageView } from './utilities/hubspot';
import ClearStorage from './pages/ClearStorage';

export const Routes = (): JSX.Element => {
  const history = useHistory();

  React.useEffect(() => {
    const { config } = new ConfigManager(window.location.origin);
    const script = document.createElement('script');

    if (config.env === 'PRD') {
      script.src = `//js.hs-scripts.com/${HUBSPOT_ID}.js`;
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.defer = true;
      script.async = true;
    }

    document.body.appendChild(script);

    return (): void => {
      document.body.removeChild(script);
    };
  }, []);

  React.useEffect(() => {
    return history.listen((location) => {
      sendPageView(`${BASE_PATH}${location.pathname}`);
    });
  }, [history]);

  return (
    <Switch>
      <Route exact path="/">
        <Homepage />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/forgot_your_password">
        <ForgotPassword />
      </Route>
      <Route exact path="/:token/validate">
        <BuildingOrg />
      </Route>
      <Route exact path="/:token/verify">
        <VerifyEmail />
      </Route>
      <Route exact path="/new_user/:prefix/:token">
        <NewUsers />
      </Route>
      <Route exact path="/password_reset/:prefix/:token">
        <PasswordReset />
      </Route>
      <Route exact path="/b2binvite">
        <Homepage b2b />
      </Route>
      <Route exact path="/clear">
        <ClearStorage />
      </Route>
    </Switch>
  );
};
