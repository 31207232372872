import React from 'react';
import { FormInputOptions } from '../../molecules/Form';
import { PasswordStrengthInput, ErrorField } from '../../particles/Input';

export interface PasswordStrengthInputOptions extends FormInputOptions {
  type: 'password';
  criteria: { done: boolean; label: string; hidden: boolean }[];
}

export default (input: PasswordStrengthInputOptions, key: number, errors: any, onChange: any) => (
  <div key={key} style={{ gridArea: input.area }}>
    <PasswordStrengthInput
      key={input.name}
      id={input.area}
      style={{ gridArea: input.area }}
      label={input.name}
      type={input.type}
      autoFocus={input.autoFocus}
      headingStyle={input.headingStyle}
      error={!!errors[input.name] || !!errors.ALL}
      width="100%"
      onChange={onChange}
      criteria={input.criteria}
    />
    {errors[input.name] ? <ErrorField>{errors[input.name]}</ErrorField> : ''}
  </div>
);
