/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import ConfigManager from '../config';
import { Result } from '../utilities/validate';
import { StateMachine } from '../pages/newUser';

const { config } = new ConfigManager(window.location.origin);
const baseUrl = `${config.backend.register}/v1`;

const requestOptions: RequestInit = {
  method: 'POST',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
};

type NewUserResponse = {
  email: string;
  extraPages: string | undefined;
};

export default {
  async verifyOrgName(name: string, captchaToken: string): Promise<Result> {
    try {
      if (!name) {
        throw new Error('Missing org name');
      }

      const data: URLSearchParams = new URLSearchParams();
      data.append('captchaToken', captchaToken);

      const resp: Response = await fetch(`${baseUrl}/verify/org_name/${name}`, {
        ...requestOptions,
        body: data,
      });

      if (resp.status === 200) {
        return Result.Success;
      }

      if (resp.status === 406) {
        return Result.OrgExists;
      }

      if (resp.status === 403) {
        return Result.CaptchaError;
      }

      return Result.GeneralError;
    } catch (e) {
      return Result.GeneralError;
    }
  },

  async createOrg(
    email: string,
    firstName: string,
    lastName: string,
    passwordHash: string,
    businessUrl: string,
    businessName: string,
    actionAfterLogin: string,
    newsletter: boolean,
    captcha: string,
  ): Promise<Result | string> {
    try {
      const data: URLSearchParams = new URLSearchParams();
      data.append('email', email);
      data.append('passwordHash', passwordHash);
      data.append('firstName', firstName);
      data.append('lastName', lastName);
      data.append('businessUrl', businessUrl);
      data.append('businessName', businessName);
      data.append('actionAfterLogin', actionAfterLogin);
      data.append('newsletter', newsletter ? 'True' : 'False');
      data.append('captchaToken', captcha);

      const options = { ...requestOptions, body: data };

      const resp: Response = await fetch(`${baseUrl}/register_org`, options);

      if (resp.ok) {
        const updateData: { updateToken: string } = await resp.json();
        return updateData.updateToken;
      }
      return Result.GeneralError;
    } catch (e) {
      return Result.GeneralError;
    }
  },

  async ResendEmail(businessName: string, updateToken: string): Promise<Result> {
    try {
      const data: URLSearchParams = new URLSearchParams();
      data.append('businessName', businessName);
      data.append('updateToken', updateToken);

      const options = { ...requestOptions, body: data };

      const resp: Response = await fetch(`${baseUrl}/resend_email`, options);

      if (resp.ok) {
        return Result.Success;
      }
      return Result.GeneralError;
    } catch {
      return Result.GeneralError;
    }
  },

  async ChangeAndResendEmail(
    businessName: string,
    newEmail: string,
    updateToken: string,
  ): Promise<Result> {
    try {
      const data: URLSearchParams = new URLSearchParams();
      data.append('businessName', businessName);
      data.append('email', newEmail);
      data.append('updateToken', updateToken);

      const options = { ...requestOptions, body: data };

      const resp: Response = await fetch(`${baseUrl}/change_email`, options);

      if (resp.ok) {
        return Result.Success;
      }
      return Result.GeneralError;
    } catch {
      return Result.GeneralError;
    }
  },
  async checkOrgBuilt(uuid: string): Promise<Result> {
    try {
      const data: URLSearchParams = new URLSearchParams();
      data.append('uuid', uuid);

      const options = { ...requestOptions, body: data };
      const resp: Response = await fetch(`${baseUrl}/check_org_built`, options);

      if (resp.ok) {
        const body = await resp.json();

        if (body.status === 3) {
          return Result.Success;
        }
        return Result.LongRunningError;
      }
      return Result.GeneralError;
    } catch (e) {
      return Result.GeneralError;
    }
  },

  async addNewUser(
    prefix: string,
    token: string,
    state: StateMachine,
    captcha: string,
  ): Promise<Result> {
    try {
      if (state.email === undefined) return Result.GeneralError;

      const data: URLSearchParams = new URLSearchParams();
      data.append('email', state.email);
      data.append('passwordHash', state.passwordHash);
      data.append('firstName', state.firstName);
      data.append('lastName', state.lastName);
      data.append('captchaToken', captcha);
      if ('position' in (state as any)) {
        const s = state as any;
        data.append('jobTitle', s.position);
        data.append('description', s.description);
        data.append('phoneNumber', s.phoneNumber);
      }
      const options = { ...requestOptions, method: 'PUT', body: data };

      const response = await fetch(`${baseUrl}/user/${prefix}/${token}`, options);

      if (response.ok) {
        return Result.Success;
      }
      return Result.GeneralError;
    } catch (e) {
      return Result.GeneralError;
    }
  },

  async GetNewUserEmail(prefix: string, token: string): Promise<NewUserResponse> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await fetch(`${baseUrl}/user/email/${prefix}/${token}`);

      if (response.ok) {
        const data = await response.json();
        return data;
      }

      if (response.status === 406) {
        throw response.status;
      }
      return { email: '' } as NewUserResponse;
    } catch (e) {
      console.log('Error fetching user email: ', e);
      throw e;
    }
  },

  async validate2FAV1(login: string, prefix: string, code: string): Promise<boolean | undefined> {
    try {
      const response = await fetch(`${baseUrl}/twofa/${login}/${prefix}/${code}`);
      if (response.ok) {
        return true;
      }
      return false;
    } catch (e) {
      return undefined;
    }
  },

  async getOrgRegToken(org: string, userName: string, userEmail: string): Promise<string> {
    try {
      const url = `${baseUrl}/get_org_uuid/${org}?userName=${userName}&userEmail=${userEmail}`;
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        return data.token;
      }

      return '';
    } catch (e) {
      console.log('getOrgRegToken: error', e);
      return '';
    }
  },
};
