/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import ConfigManager from '../config';

const { config } = new ConfigManager(window.location.origin);
const baseUrl = `${config.backend.analytics}`;

const requestOptions: RequestInit = {
  method: 'POST',
  headers: { 'Content-Type': 'application/JSON' },
};

export default {
  async trackRegisterPage(uuid: string): Promise<void> {
    try {
      const data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_uuid: uuid,
      };
      const options = { ...requestOptions, body: JSON.stringify(data) };
      await fetch(`${baseUrl}/user/registering`, options);
    } catch (e) {
      console.error(e);
    }
  },

  async trackOrgBuiltPage(uuid: string, prefix: string, promotion: boolean): Promise<void> {
    try {
      const data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_uuid: uuid,
        prefix,
        special: promotion,
      };
      const options = { ...requestOptions, body: JSON.stringify(data) };
      await fetch(`${baseUrl}/user/built`, options);
    } catch (e) {
      console.error(e);
    }
  },
};
