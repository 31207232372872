import validate, { Result } from './validate';

type FormFieldsMap = Record<string, string>;

type ErrorsMap = Record<string, string>;

export const getPasswordFormErrors = (values: FormFieldsMap): ErrorsMap => {
  const password = values.PASSWORD;
  const confirm = values['CONFIRM PASSWORD'];

  const newErrors: ErrorsMap = {};

  if (validate.checkEmpty([password]) !== Result.Success) {
    newErrors.PASSWORD = Result.RequiredField;
  }

  if (validate.checkEmpty([confirm]) !== Result.Success) {
    if (newErrors.PASSWORD) {
      return { ALL: Result.EmptyField };
    }

    newErrors['CONFIRM PASSWORD'] = Result.RequiredField;
  }

  if (validate.checkPasswordCapital(password) !== Result.Success) {
    newErrors.PASSWORD = Result.InvalidPasswordCapital;
  } else if (validate.checkPasswordLength(password) !== Result.Success) {
    newErrors.PASSWORD = Result.InvalidPasswordLength;
  } else if (validate.checkPasswordNumber(password) !== Result.Success) {
    newErrors.PASSWORD = Result.InvalidPasswordNumber;
  }

  if (!newErrors.PASSWORD && validate.checkPasswordsMatch(password, confirm) !== Result.Success) {
    newErrors.ALL = Result.PasswordMatch;
  }

  return newErrors;
};

type Criteria = {
  done: boolean;
  label: string;
  hidden: boolean;
};

export const getPasswordFormCriteria = (values: FormFieldsMap): Criteria[] => {
  const password = values.PASSWORD || '';

  return [
    {
      done: validate.checkPasswordCapital(password) === Result.Success,
      label: 'One uppercase',
      hidden: false,
    },
    {
      done: validate.checkPasswordNumber(password) === Result.Success,
      label: 'One number',
      hidden: false,
    },
    {
      done: validate.checkPasswordLength(password) === Result.Success,
      label: 'Minimum 5 characters',
      hidden: false,
    },
    {
      done: password.length >= 8,
      label: 'Better 8 characters',
      hidden: true,
    },
    {
      done: /[!@#$%^&*]/.test(password),
      label: 'Better symbols',
      hidden: true,
    },
    {
      done: /[a-z]/.test(password),
      label: 'Better lowercase',
      hidden: true,
    },
  ];
};
