import React, { useState } from 'react';
import Form from '../tauria-react-components/molecules/Form';
import PasswordStrength, {
  PasswordStrengthInputOptions,
} from '../tauria-react-components/molecules/FormRenderers/PasswordStrength';
import Base from '../pages/Base';
import { Result } from '../utilities/validate';
import { Headings } from '../styles';
import { getPasswordFormErrors, getPasswordFormCriteria } from '../utilities/password-criteria';
import Loading from '../views/Loading';
import { sendPageView } from '../utilities/hubspot';

const Password: React.FC<{
  storePassword: (data: Record<string, string>) => Promise<Result | undefined>;
  newUser?: boolean;
}> = ({ storePassword, newUser }) => {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [criteria, setCriteria] = useState(getPasswordFormCriteria({}));
  const [loadingPromise, setLoadingPromise] = useState<Promise<Result | undefined> | undefined>(
    undefined,
  );

  React.useEffect(() => {
    document.title = 'Set Password';
    sendPageView(`${window.location.pathname}#set-password`);
  }, []);

  const validatePasswords = React.useCallback(
    async (values: Record<string, string>): Promise<void> => {
      const newErrors = getPasswordFormErrors(values);

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      const promise = storePassword(values);

      if (newUser) {
        setLoadingPromise(promise);
      }

      const potentialErrors = await promise;

      if (newUser) {
        setTimeout(() => {
          setLoadingPromise(undefined);
        }, 2000);
      }

      if (potentialErrors) {
        setErrors({ ALL: potentialErrors });
      }
    },
    [storePassword, newUser],
  );

  const handleChange = React.useCallback((values: Record<string, string>): void => {
    setCriteria(getPasswordFormCriteria(values));

    // Reset Errors on change
    setErrors({});
  }, []);

  return (
    <>
      <Base showFooter={false}>
        <Headings>
          <h1>Set Password</h1>
          <h2>Set a password for your own account</h2>
        </Headings>
        <Form
          inputs={[
            {
              name: 'PASSWORD',
              area: 'password',
              type: 'password',
              renderer: PasswordStrength,
              criteria,
              autoFocus: true,
            } as PasswordStrengthInputOptions,
            { name: 'CONFIRM PASSWORD', area: 'confirm', type: 'password' },
          ]}
          errors={errors}
          submit={{ name: 'NEXT', area: 'submit', type: 'button' }}
          rows={[1, 1]}
          colms={[1]}
          areas={[['password'], ['confirm'], ['submit']]}
          onSubmit={validatePasswords}
          onChange={handleChange}
        />
      </Base>
      <Loading promise={loadingPromise} message="Creating your user. Thank you" />
    </>
  );
};

export default Password;
