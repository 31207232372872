const useDownload = (openFunction: (url: string) => void): (() => void) => {
  return (): void => {
    const { userAgent } = navigator;
    // Is iOS device
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      openFunction('https://itunes.apple.com/ca/app/zebu-app/id1292784671?mt=8');
    }
    // Is android device
    else if (userAgent.toLowerCase().indexOf('android') > -1) {
      openFunction('https://play.google.com/store/apps/details?id=io.zebu.app');
    } else {
      openFunction('https://1162100038.rsc.cdn77.org/download/latest');
    }
  };
};

export default useDownload;
